.data-card {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    border: 1px solid #e7ecf4;
    border-radius: 12px;
    text-align: left;
    background: #fff;
    width: 250px;
    .data-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .data-total {
        font-size: 30px;
        font-weight: bold;
        color: #4d5bd8;
        margin-bottom: 20px;
    }
    .data-icon {
        margin: auto auto 16px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        width: 64px;
        height: 64px;
        justify-content: center;
        color: #4d5bd8;
        background-image: linear-gradient(135deg, rgba(12, 83, 183, 0) 0%, rgba(12, 83, 183, 0.24) 100%);
    }
}
.data-export {
    padding: 40px 20px;
    .title {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 16px;
    }
}

.history-export {
    .statusAlert {
        margin-top: 16px;
        border-radius: 40px;
        color: #8fa4c2;
        background-color: rgba(244, 247, 254, 1);
        padding: 2px 10px;
        border: 1px solid rgba(231, 236, 244, 1);
    }
}
