.whatsapp-content {
    display: flex;
    padding: 0 20px 20px;

    p {
        word-break: break-all;
    }

    .select-way {
        label {
            color: #AAB9DD;
        }

        margin-bottom: 10px;
    }

    .whatsapp-input {
        margin-bottom: 10px;
        width: 400px;

    }

    .whatsapp-preview {
        padding: 8px;
        width: 300px;
        border: 1px solid #eee;
        border-radius: 8px;
        margin-left: 16px;
        overflow: hidden;
        height: 400px;
        display: flex;
        flex-direction: column;


        .title {
            font-weight: bold;
        }

        .whatsapp-body {
            overflow-y: auto;
        }

        pre {
            white-space: pre-wrap;
            word-wrap: break-word;
            color: rgb(170, 185, 221);
            font-size: 16px;
        }

    }


    .imgBox {
        width: 100%;
        height: 200px;
        background: #eee;
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .imgUploadBox {
        margin-bottom: 20px;

        label {
            padding: 0 8px;
        }
    }
}