.floorPlanBottomFeature {
    display: flex;
    align-items: center;
    margin: 0;

    li {
        display: flex;
        align-items: center;

        .iconButton {
            border-radius: 5px;
            padding: 4px;
        }

        .icon {
            color: #878e9a;
        }

        .gridIcon {
            width: 22px;
            height: 22px;
        }

        .deleteIcon {
            width: 25px;
            height: 25px;
        }

        .zoomIcon {
            width: 25px;
            height: 25px;
        }

        p {
            color: #878e9a;
            padding: 0 5px;
            margin: 0;
        }

        .gray-box {
            display: block;
            width: 20px;
            height: 20px;
            background: #e6e8f3;
            border-radius: 10px;

        }

        .green-box {
            display: block;
            width: 20px;
            height: 20px;
            background: #5898e1;
            border-radius: 10px;
        }

        .dark-grey-box {
            display: block;
            width: 20px;
            height: 20px;
            background: rgba(0, 0, 0, 0.12);
            border-radius: 10px;
        }

        .orange-box {
            display: block;
            width: 20px;
            height: 20px;
            background: linear-gradient(to right bottom, #ffbbbb, #ff8058);
            border-radius: 5px;
        }
    }

    .actioncoordinate-view {
        min-width: 100px;
    }
}

.reservation-table-action {

    &-total {
        display: flex;
    }

    &-item {
        display: flex;
        font-size: 16px;
        align-items: center;
        margin-right: 16px;
    }

    &-num {
        font-size: 24px;
        color: #5898E1;
        margin: 0 8px;
    }
}