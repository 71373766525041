.codeImage {
    height: 504px;
    width: 672px;
    padding: 28px 0;
    border: 7px solid #ffdc33;
    border-radius: 40px;
    background: #fff;
    box-sizing: border-box;
    overflow: hidden;

    .codeImageTitle {
        font-size: 40px;
        font-weight: bold;
        line-height: 48px;
        color: #323161;
        white-space: nowrap;
        margin-bottom: 24px;
    }

    .codeImageTip {
        font-size: 18px;
        color: #333;
        line-height: 24px;
    }

    .codeDiv {
        position: relative;
        display: inline-block;

        .codeContentRight {
            width: 80%;
            position: absolute;
            bottom: 23px;
            right: 0;
            text-align: left;
            transform: translate(100%, 0);

            .codeContentRightText {
                writing-mode: vertical-rl;
            }

            .qrCodeGooglePlay {
                margin: 10px 0 30px 0;
            }
        }
    }

    .codeContentTip {
        font-size: 20px;
        color: #333;
    }
}

.codeDetail {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-left: 20px;

    .content {
        text-align: left;
        overflow: auto;
        padding-right: 20px;
    }
}