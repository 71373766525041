$defaultTransition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);

@keyframes backOutUp {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    20% {
        transform: translateY(0px) scale(0.95);
        opacity: 0.7;
    }

    100% {
        transform: translateY(-110%) scale(0.98);
        opacity: 0.1;
    }
}

.backOutUp {
    animation: backOutUp 800ms none ease-in-out;
}
@keyframes backOutDown {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    20% {
        transform: translateY(0px) scale(0.95);
        opacity: 0.7;
    }

    100% {
        transform: translateY(110%) scale(0.98);
        opacity: 0.1;
    }
}

.backOutDown {
    animation: backOutDown 800ms none ease-in-out;
}
@keyframes backInUp {
    0% {
        transform: translateY(110%) scale(0.95);
        opacity: 0.1;
    }

    80% {
        transform: translateY(0px) scale(0.98);
        opacity: 0.9;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.backInUp {
    animation: backInUp 800ms none ease-in-out;
}

@keyframes backInDown {
    0% {
        transform: translateY(-110%) scale(0.95);
        opacity: 0.1s;
    }

    80% {
        transform: translateY(0px) scale(0.98);
        opacity: 0.9;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.backInDown {
    animation: backInDown 800ms none ease-in-out;
}

.delay {
    animation-delay: 800ms;
}

// 淡入
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.animate-fadeIn {
    animation: fadeIn 300ms ease-in-out;
}

// 淡入
@keyframes flashing {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
// 闪烁边框
@keyframes flashingBorder {
    0% {
        opacity: 1;
        border: 2px solid transparent;
        border-radius: 4px;
    }
    50% {
        opacity: 1;
        border: 2px solid #5f8adc;
        border-radius: 4px;
    }
    100% {
        opacity: 1;
        border: 2px solid transparent;
        border-radius: 4px;
    }
}

// 震动动画
@keyframes shakeX {
    0% {
        transform: translateX(-2px);
    }
    50% {
        transform: translateX(2px);
    }
    100% {
        transform: translateX(0px);
    }
}

// 闪烁
.animate-flashing {
    animation: flashing 1000ms ease-in-out infinite;
}
// 二次 停 引用时1s移除className
.animate-flashing-stop {
    animation: flashing 500ms ease-in-out 2;
}
// 引用时1.6s移除className - 闪烁边框
.animate-flashing-border-stop {
    animation: flashingBorder 800ms ease-in-out infinite;
}
// 引用时0.4s移除className - 震动动画
.animate-shakeX-stop {
    animation: shakeX 200ms ease-in-out 2;
}

// 骨架屏
.MuiSkeleton-wave {
    overflow: hidden;
    position: relative;
    background-color: rgba(255, 255, 255, 0.08);
    height: auto;
    border-radius: 4px;
    transform-origin: 0 60%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.MuiSkeleton-wave::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    animation: MuiSkeleton-keyframes-wave 600ms linear 0s infinite;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.08), transparent);
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
@keyframes MuiSkeleton-keyframes-wave {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(150%);
    }
}

// 蹦
.animate-bounce:hover {
    // border: 1px solid;
    box-shadow: inset 0 0 20px #b8b6b680, 0 0 20px #ffffff33;
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
    text-shadow: 1px 1px 1px #000000ba;
    transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
}

// 闪光过渡 - hover
.animate-lightning {
    // ! overflow position 必须要的；
    overflow: hidden;
    position: relative;
}
.animate-lightning:after {
    background: #00000042;
    content: "";
    height: 185px;
    left: -100px;
    opacity: 0.15;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: $defaultTransition;
    width: 50px;
}
.animate-lightning:hover:after {
    left: 150%;
    transition: $defaultTransition;
}

// back in up
.animate-backInUp {
    animation: animateBackInUp 500ms none ease-in-out;
}

@keyframes animateBackInUp {
    0% {
        transform: translateY(100%);
        opacity: 0.1;
    }

    50% {
        opacity: 0.6;
    }

    80% {
        transform: translateY(0px);
        opacity: 0.9;
    }

    100% {
        opacity: 1;
    }
}

// back out up
.animate-backOutUp {
    animation: animateBackOutUp 500ms none ease-in-out;
}

@keyframes animateBackOutUp {
    0% {
        opacity: 1;
    }

    80% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}