.rider-management {
    .top {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .title {
            font-size: 32px;
            font-weight: bold;
        }
    }
    .add {
        display: flex;
        justify-content: flex-end;
        .addIcon {
            color: rgb(77, 91, 216);
            font-size: 38px;
            cursor: pointer;
        }
    }
}

.rider-management-staff {
    background: #f1f4f8;
    height: 100vh;
    .rider-management-p30 {
        padding: 30px;
    }
}

.staff-list {
    background: #fff;
    padding: 20px;
    min-width: 800px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .title {
            font-size: 23px;
        }
    }
    .staff-list-table{
        flex: 1;
        overflow: auto;
        padding-bottom: 20px;
    }
}

.rider-detail {
    background: #fff;
    padding: 20px;
    min-width: 800px;
    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .title {
            font-size: 23px;
        }
    }

    .account-basic-info {
        width: 60%;
        height: 210px;
        box-shadow: 7px 10px 7px #f4f8ff;
    }
    .frame {
        margin-top: 15px;
        padding: 20px 20px;
        background: #fff;
        border-radius: 8px;
        border-color: #f4f7fb;
        border: 1px solid #c9d8ef;
    }
    .bt-bar {
        display: flex;
        justify-content: flex-end;
        .btn {
            background-color: #4d5bd8;
            color: #fff;
        }
    }
}
