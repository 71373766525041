// 新建顾客模态框
.login_mode {
    // 模态框头部
    .login_mode_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: -10px;
        margin-bottom: -10px;
    }
    // 模态框底部
    .commonFooter {
        margin-right: 10px;
        button {
            margin-right: 8px;
        }
    }
    // 模态框内容
    .login_content {
        height: 460px;
        display: flex;
        padding: 20px 24px 20px;
        justify-content: center;
        align-items: center;
        .select-option {
            width: 100%;
        }
    }
}

// 手机号注册
.phone_login {
    position: relative;
    .loading {
        width: 100%;
        height: 100%;
    }
    .phone_form {
        width: 500px;
    }
    .sendCode {
        color: rgb(170, 185, 221);
        background: #f4f7fb;
        border-radius: 5px;
        height: 56px;
        width: 100%;
    }
    .sendCode:hover {
        color: #fff;
        background: rgba(77, 91, 216, 0.9);
    }
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: unset;
}

.third-part-login {.base-fab {
    color: rgba(0, 0, 0, 0.87);
    width: 56px;
    height: 56px;
    padding: 0;
    font-size: 0.875rem;
    min-width: 0;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,.2), 0px 6px 10px 0px rgba(0,0,0,.14), 0px 1px 18px 0px rgba(0,0,0,.12);
    box-sizing: border-box;
    min-height: 36px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 50%;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    background-color: #e0e0e0;

    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

    text-align: left;
    padding: 20px;
    min-height: 200px;
    .google-label {
        margin: 10px;
    }
    .my-facebook-button-class {
        color: rgba(255, 255, 255, 0);
        border: none;
        background: #fff;
        outline: none;
        width: 90px;
        height: 70px;
    }
}
