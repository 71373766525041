.history-list {
    height: calc(100vh - 64px);
    overflow: hidden;
    .MuiPaper-root {
        overflow: hidden;
    }
    .topPart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .commonBtn {
            border-radius: 20px;
            height: 40px;
            line-height: 40px;
            padding: 0 16px;
            font-size: 12px;
        }
        .orangeBtn,
        .orangeBtn:hover {
            color: #fff;
            background-color: #ff8058;
            border: 1px solid #ff8058;
        }
        .ml16 {
            margin-left: 16px;
        }
        .otherBtn {
            color: #b4c4ee;
            border: 1px solid #bfcae8;
        }
        .otherBtn:hover {
            background-color: transparent;
        }
    }

    .content {
        padding: 0 30px;
        flex: 1 1 auto;
        overflow: hidden;
        min-height: 400px;
    }
}

.dishTop {
    border: 1px solid rgb(201, 216, 239);
    border-radius: 8px;
    padding: 20px;
    margin: 20px 30px 20px 0;
    box-shadow: 7px 10px 7px #f4f8ff;
}

.points-record {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
    overflow: hidden;
    padding: 20px;
    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            .reservation_order_part {
                display: flex;
                padding: 8px;
                margin-left: 50px;
                .selectSearch {
                    border: 1px solid #e7ecf4;
                    border-radius: 20px;
                    .searchSelsctValue {
                        margin: 0;
                        .MuiSelect-select.MuiSelect-select {
                            width: auto;
                            border-radius: 0 20px 20px 0;
                            border: none;
                            border-right: 1px solid #e7ecf4;
                        }
                    }
                }
                .selectinput {
                    display: inline-flex;
                    .MuiFormControl-root {
                        margin-left: 0;
                        .MuiSelect-select.MuiSelect-select {
                            width: auto;
                            border-radius: 20px 0 0 20px;
                            border: none;
                            border-right: 1px solid #e7ecf4;
                        }
                    }
                }
                .datePicker {
                    .commonBtn {
                        border-radius: 0 20px 20px 0;
                        height: 40px;
                        line-height: 40px;
                        padding: 0 16px;
                        font-size: 12px;
                        margin-left: 0;
                    }
                    .whiteBtn,
                    .whiteBtn:hover {
                        color: #8fa4c2;
                        background-color: #fff;
                    }
                }
            }
            .text {
                font-size: 24px;
            }
        }
    }
}
