.mop-setting {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    position: relative;
    .loading {
        width: 100%;
    }

    .title {
        font-size: 32px;
        margin-bottom: 32px;
        font-weight: bold;
        color: black;
    }

    .setting-area-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
    }

    .mop-all-sellect-btn {
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        background-color: #0a44da;
        margin-right: 30px;
    }

    .mop-menu-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 20px;
        .menu-item {
            margin-right: 15px;
            margin-bottom: 15px;
        }
    }

    .mop-img-box {
        display: flex;
        flex-direction: column;
        background: #f1f4f8;
        justify-content: flex-start;
        padding: 15px;
        margin-top: 10px;
        border-radius: 12px;

        .mop-img-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            // margin-bottom: 20px;
            overflow-x: scroll;

            .mop-img-item {
                margin-right: 50px;
                margin-bottom: 15px;
                width: 140px;
                height: 140px;
            }
        }

        .mop-img-list::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 4px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 6px;
        }

        /*滚动条的轨道*/
        .mop-img-list::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px #f1f4f8;
            border-radius: 5px;
            background: #f1f4f8;
        }

        /*滚动条里面小滑块*/
        .mop-img-list::-webkit-scrollbar-thumb {
            border-radius: 8px;
            -webkit-box-shadow: inset 0 0 5px #fff;
            background: #fff;
        }
    }
}
