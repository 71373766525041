.google-map-search {
    .searchBox {
        background: #f4f7fb;
        border-radius: 5px;
        .searchBoxinput {
            width: calc( 98% - 16px );
            border: none;
            background: rgb(244, 247, 251);
            outline: none;
            font-size: 18px;
            color: rgb(170, 185, 221);
            border-radius: 5px;
            font-weight: 400;
            padding: 27px 12px 7px;
        }
    }
}
