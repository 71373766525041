.root {
    color: #AAB9DD !important;
    width: 42% !important;
    height: 38px !important;
    border-Radius: 10px !important;
}

.illegal {
    color: #AAB9DD !important;
    width: 42% !important;
    height: 38px !important;
    border-Radius: 10px !important;
    .MuiOutlinedInput-notchedOutline  {
        border-Color: red !important;
    }
}