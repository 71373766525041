.help-center {
    display: flex;
    flex-direction: column;
    background: #fafafa;

    &-title {
        flex: 1;
    }

    &-container {
        display: flex;
        flex: 1;
        margin: 8px 0;
        min-height: 100vh;
    }

    &-nav {
        flex-shrink: 0;
        margin: 16px 0;
        height: 100px;
        width: 300px;
        background: #fff;
        padding: 16px;
        border-radius: 10px;

        a {
            color: #000;
            text-decoration: none;
        }
    }

    &-content {
        text-align: left;
        background: #fff;
        margin: 16px;
        border-radius: 10px;
    }

    img {
        width: 100%;
        height: auto;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
    }


    header {
        background: #4d5bd8;
        text-align: left;
        box-shadow: none;

        a {
            color: #fff;
            text-decoration: none;
        }
    }

    .footer {
        background: #4d5bd8;
        padding: 16px;
        color: #fff;

        a {
            margin-left: 10px;
            color: #fff;
            position: absolute;
            right: 16px;
        }
    }

    #google-booking {
        padding: 0 16px 16px;
    }

    .MuiTreeItem-label {
        height: auto;
    }
}