.map-order {
  display: flex;
  flex-direction: column;
  text-align: left;
  // height: calc(100vh - 150px);
  // overflow: hidden;
  width: 100%;
  .map-order-setting-btn {
      flex-direction: row;
      text-align: left;
      flex-wrap: wrap;
      .setting-btn-select {
          height: 42px;
          border-radius: 88px;
          min-width: 192px;
          color: #fff;
          background: #4d5bd8;
          margin-right: 10px;
          margin-bottom: 10px;
      }
      .setting-btn-select.Mui-disabled {
          background: #9ea7e8;
      }
      .setting-btn-selected {
          min-width: 192px;
          border-radius: 88px;
          background: #ff8058;
          color: #fff;
          margin-right: 10px;
          margin-bottom: 10px;
      }
  }
  .statusAlerts {
      background: #f4f7fe;
      border: 1px solid #dce5f3;
      color: #8fa4c2;
  }
  .reservation_order_chip {
      text-align: left;
      padding: 0 8px;
  }
  .map-or-order {
      display: flex;
      flex-direction: row;
      text-align: left;
      // margin-top: 20px;
      .order {
          background: #f1f4f8;
          overflow-y: auto;
          height: 80vh;
          width: 380px;
          .order-btn {
              display: flex;
              flex-direction: row;
              margin-bottom: 6px;
              .time-icon {
                  flex: 1;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  margin-right: 5px;
              }
              .time-hidden {
                  flex: 1;
                  display: none;
              }
          }
          .zone-btn {
              display: flex;
              background: #fff;
              border-radius: 16px;
              margin: 17px 5px 16px 5px;
          }
      }
      .map {
          display: flex;
          flex: 2;
          margin-left: 10px;
          
      }
  }
}

.noData {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
  color: #8fa4c2;
  width: 100%;
}
.card-order-map {
  display: flex;
  width: 368px;
  height: 128px;
  border-radius: 12px;
  margin-top: 10px;
  flex-direction: row;
  .card-name {
      display: flex;
      width: 120px;
      padding: 10px;
      text-align: left;
      flex-direction: column;
      overflow: hidden;
      justify-content: space-around;
  }
  .separate-line {
      margin-top: 14px;
      height: 100px;
      border-left: 1px solid #e5ecf7;
  }
  .card-phone {
      display: flex;
      padding: 10px;
      width: 150px;
      text-align: left;
      flex-direction: column;
      justify-content: space-around;
      .order-status-btn {
          border: 1px solid #dce5f3;
          background: #f4f7fe;
          color: #8fa4c2;
          border-radius: 88px;
          padding: 0px 10px 0px 10px;
          font-size: 13px;
      }
  }
  .card-btn {
      display: flex;
      padding: 10px;
      width: 40px;
      text-align: left;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .card-checkbox {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 88px;
          background: #d9dde6;
          border: none;
          width: 16px;
          height: 16px;
      }
      .card-checkbox-selected {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 88px;
          background: #7f89e3;
          border: none;
          width: 16px;
          height: 16px;
      }
      .card-checkbox-selected {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 88px;
          background: #7f89e3;
          border: none;
          width: 16px;
          height: 16px;
      }
  }
}

// 信息框样式
// 信息框内容
.infoContent {
  min-width: 60px;
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      .name {
          font-weight: bold;
          margin-bottom: 6px;
          width: 60px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
      }
  }
}
.infoArrow {
  content: "";
  height: 10px;
  left: 50%;
  position: absolute;
  bottom: -2px;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 10px;
}

// 选择框
.checkBox {
  display: none;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  text-align: center;
  margin-right: 8px;
  .check {
      font-weight: bold;
      font-size: 20px;
      display: none;
  }
}
// 选择状态
.select {
  .checkBox {
      display: inline-block;
      .check {
          display: none;
      }
  }
}

// 选中
.selected {
  .checkBox {
      display: flex;
      justify-content: center;
      align-items: center;
      .check {
          display: inline;
      }
  }
}
// 未确认
.unconfirm {
  // 信息框内容
  .infoContent {
      background: #fff;
      -moz-box-shadow: 0px 4px 5px rgba(77, 91, 216, 0.117647058823529);
      -webkit-box-shadow: 0px 4px 5px rgba(77, 91, 216, 0.117647058823529);
      box-shadow: 0px 4px 5px rgba(77, 91, 216, 0.117647058823529);
      .name {
          color: #4d5bd8;
      }
  }
  .infoArrow {
      background: #fff;
  }
  // 选择
  &.select {
      .checkBox {
          background: #d9dde6;
      }
  }
  // 选中
  &.selected,
  &.infoActive,
  &.infoTotalActive {
      .infoContent {
          color: #fff;
          background: #4d5bd8;
          -moz-box-shadow: 0px 5px 5px rgba(77, 91, 216, 0.117647058823529);
          -webkit-box-shadow: 0px 5px 5px rgba(77, 91, 216, 0.117647058823529);
          box-shadow: 0px 5px 5px rgba(77, 91, 216, 0.117647058823529);
          .name {
              color: #fff;
          }
      }
      .infoArrow {
          background: #4d5bd8;
      }
      .checkBox {
          background: #fff;
          .check {
              color: #4d5bd8;
          }
      }
  }
}

// 已确认
.confirmed {
  // 信息框内容
  .infoContent {
      color: #fff;
      background: #27d086;
      .name {
          color: #fff;
      }
  }
  .infoArrow {
      background: #27d086;
  }
  // 选择
  &.select {
      .checkBox {
          background: #fff;
      }
  }
  // 选中
  &.selected,
  &.infoActive,
  &.infoTotalActive {
      // 信息框内容
      .infoContent {
          color: #fff;
          background: #4d5bd8;
          -moz-box-shadow: 0px 5px 5px rgba(77, 91, 216, 0.117647058823529);
          -webkit-box-shadow: 0px 5px 5px rgba(77, 91, 216, 0.117647058823529);
          box-shadow: 0px 5px 5px rgba(77, 91, 216, 0.117647058823529);
          .name {
              color: #fff;
          }
      }
      .infoArrow {
          background: #4d5bd8;
      }
      .checkBox {
          background: #27d086;
          .check {
              color: #fff;
          }
      }
  }
}
// 配送中
.deliverying {
  // 信息框内容
  .infoContent {
      color: #fff;
      background: #0ec5ff;
      -moz-box-shadow: 0px 4px 5px rgba(77, 91, 216, 0.117647058823529);
      -webkit-box-shadow: 0px 4px 5px rgba(77, 91, 216, 0.117647058823529);
      box-shadow: 0px 4px 5px rgba(77, 91, 216, 0.117647058823529);
      .name {
          color: #fff;
      }
  }
  .infoArrow {
      background: #0ec5ff;
  }
  // 选择
  &.select {
      .checkBox {
          background: #fff;
      }
  }
  // 选中
  &.selected,
  &.infoActive,
  &.infoTotalActive {
      // 信息框内容
      .infoContent {
          color: #fff;
          background: #4d5bd8;
          -moz-box-shadow: 0px 5px 5px rgba(77, 91, 216, 0.117647058823529);
          -webkit-box-shadow: 0px 5px 5px rgba(77, 91, 216, 0.117647058823529);
          box-shadow: 0px 5px 5px rgba(77, 91, 216, 0.117647058823529);
          .name {
              color: #fff;
          }
      }
      .infoArrow {
          background: #4d5bd8;
      }
      .checkBox {
          background: #0ec5ff;
          .check {
              color: #fff;
          }
      }
  }
}
// 信息总框
.totalInfo {
  .infoContent {
      padding: 8px 0 8px 20px;
      .infoBtn {
          writing-mode: vertical-rl;
          height: 40px;
          text-align: center;
          width: 30px;
          font-size: 40px;
          line-height: 10px;
          margin-left: 20px;
          border-left: 1px solid #fff;
      }
  }
}

.order-card-component {
  flex: 1;
  overflow: hidden;
}
.new-card-order {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  border: 1px solid #e7ecf4;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background: #fff;
  width: 368px;
  .leftLine {
      width: 4px;
      height: 83px;
      background: #fff;
  }
  .card {
      display: flex;
      flex-direction: column;
      width: 364px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      .upon {
          flex: 2;
          display: flex;
          flex-direction: column;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          .name {
              display: flex;
              flex: 1;
              flex-direction: row;
              justify-content: space-between;
              padding: 5px 20px;
              align-items: center;
              .name-time {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  width: 290px;
                  align-items: center;
                  .name-text {
                      font-size: 14px;
                      width: 160px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-align: left;
                      text-overflow: ellipsis;
                      font-weight: 600;
                  }
                  .time {
                      color: #4d5bd8;
                      font-size: 14px;
                      font-weight: 600;
                      width: 100px;
                      text-align: right;
                  }
              }
              .select {
                  width: 24px;
                  height: 24px;
                  border-radius: 88px;
                  display: none;
                  svg {
                      display: none;
                  }
              }
          }
          .phone {
              display: flex;
              flex: 1;
              flex-direction: row;
              justify-content: space-between;
              padding: 0px 20px;
              align-items: center;
              margin-top: -10px;
              .name-time {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  width: 290px;
                  align-items: center;
                  .phone-text {
                      font-size: 13px;
                      color: #beb9c6;
                  }
                  .time-year {
                      font-size: 13px;
                      color: #beb9c6;
                  }
              }
          }
      }
  }
  .visibilityIcon {
      color: #4d5bd8;
  }
  // 点击
  &.card-active {
      .card {
          background: #4d5bd8;
          .upon {
              .name {
                  .name-time {
                      .name-text {
                          color: #fff;
                      }
                      .time {
                          color: #fff;
                      }
                  }
              }
              .phone {
                  .name-time {
                      .phone-text {
                          color: #fff;
                      }
                      .time-year {
                          color: #fff;
                      }
                  }
              }
          }
      }
      .visibilityIcon {
          color: #fff;
      }
  }
  // 选择
  &.selected {
      .card {
          .upon {
              .name {
                  .select {
                      display: flex;
                      svg {
                          display: none;
                      }
                  }
              }
          }
      }
  }
  // 选中
  &.checked {
      .card {
          background: #4d5bd8;
          .upon {
              .name {
                  .name-time {
                      .name-text {
                          color: #fff;
                      }
                      .time {
                          color: #fff;
                      }
                  }
                  .select {
                      display: flex;
                      svg {
                          display: flex;
                      }
                  }
              }
              .phone {
                  .name-time {
                      .phone-text {
                          color: #fff;
                      }
                      .time-year {
                          color: #fff;
                      }
                  }
              }
          }
      }
      .visibilityIcon {
          color: #fff;
      }
  }
  // 已确认
  &.confirmed {
      .leftLine {
          background: rgb(39, 208, 134);
      }
      .card {
          .upon {
              .name {
                  .select {
                      background: rgb(39, 208, 134);
                  }
              }
          }
      }
  }
  // 配送中
  &.deliverying {
      .leftLine {
          background: #0ec5ff;
      }
      .card {
          .upon {
              .name {
                  .select {
                      background: #0ec5ff;
                  }
              }
          }
      }
  }
  // 未确认
  &.unconfirm {
      .card {
          .upon {
              .name {
                  .select {
                      background: #d9dde6;
                  }
              }
          }
      }
  }
}

.new-card-order.disabled {
  opacity: 0.8;
}

.activeText {
  color: #fff;
}

.badge-order {
  min-width: 368px;
  .MuiBadge-badge {
      color: #fff;
      padding: 10px;
      border-radius: 88px;
      margin-right: 26px;
      margin-top: 10px;
  }
  .MuiBadge-colorSecondary {
      color: #fff;
      padding: 10px;
      border-radius: 88px;
      margin-right: 28px;
      margin-top: 10px;
  }
}
.zIndex_9 {
  z-index: 9 !important;
}

.order::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
}
.reservation_order_part {
  display: inline-flex;
  height: 43px;
  .selectSearch {
      border: 1px solid #e7ecf4;
      border-radius: 20px;
      .searchSelsctValue {
          margin: 0;
          .MuiSelect-select.MuiSelect-select {
              width: auto;
              border-radius: 0 20px 20px 0;
              border: none;
          }
      }
      .datePicker {
          .commonBtn {
              border-radius: 0 20px 20px 0;
              height: 40px;
              line-height: 40px;
              padding: 0 16px;
              font-size: 12px;
              margin-left: 0;
          }
          .whiteBtn,
          .whiteBtn:hover {
              color: #8fa4c2;
              background-color: #fff;
              border: none;
          }
      }
  }
  .selectinput {
      display: inline-flex;
      .MuiFormControl-root {
          margin-left: 0;
          .MuiSelect-select.MuiSelect-select {
              width: auto;
              border-radius: 20px 0 0 20px;
              border: none;
              border-right: 1px solid #e7ecf4;
          }
      }
  }
}

.orderListFilterSelect {
  display: inline-flex;
  margin-left: 16px;
}

// 添加订单到队列
.order-queue-dialog{
    .order-queue-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        .cancelBtn {
            background: #f1f4f8;
            color: #4d5bd8;
            margin-right: 16px;
        }
}
.order-queue {
  .order-queue-content {
      height: calc(100vh - 260px);
      display: flex;
      padding: 8px 24px 12px;
      .order-queue-left {
          flex: none;
          width: 370px;
          display: flex;
          flex-direction: column;
          .order-queue-left-top {
              flex: none;
              .MuiAccordionSummary-content {
                  margin: 12px 0;
              }
              .MuiAccordionDetails-root {
                  background: #d1e0f2;
              }
          }
          .order-queue-left-center {
              flex: auto;
              overflow: auto;
          }
        
      }
      .order-queue-right {
          flex: auto;
          width: 400px;
          min-height: 550px;
          margin-top: -10px;
          margin-left: 20px;
          .order-google-map{
              padding-top: 10px;
          }
      }
  }
}
  .order-queue-left-center::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 0px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 0px;
  }
}

.gm-ui-hover-effect {
  display: none !important;
}
.gm-style-iw-d {
  overflow: hidden !important;
  box-shadow: none !important;
  background: rgba(255, 255, 255, 0);
  border: none;
  outline: none;
}
.gm-style-iw-c {
  box-shadow: none !important;
  background: rgba(255, 255, 255, 0) !important;
  border: none !important;
  outline: none !important;
  padding: 0px !important;
}
.gm-style-iw-t::after {
  display: none;
}

.gm-style-iw-tc{
    display: none;
}

.order-history-table {
  height: calc(100vh - 215px);
  .time {
      text-align: left;
      color: #bfc0c3;
      margin-top: 20px;
  }
}

.index-badge-zone {
  width: 100%;
  .MuiBadge-badge {
      color: #fff;
      padding: 12px;
  }
  .MuiBadge-colorSecondary {
      color: #fff;
      padding: 12px;
      border-radius: 88px;
  }
  .MuiBadge-anchorOriginTopRightRectangle {
      transform: scale(0.8) translate(10%, -40%);
  }
}

.order-google-map {
  width: 100%;
  height: 100%;
}
