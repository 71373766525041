.queue-component {
    margin-bottom: 15px;

    .queue-detail {
        width: 368px;
        height: 134px;
        display: flex;
        flex-direction: column;
        border: 1px solid #e1e6ed;
        border-radius: 12px;
        background-color: #fff;

        .upper-half {
            display: flex;
            height: 78px;
            flex-direction: column;
            justify-content: center;
            padding-left: 20px;
            padding-right: 20px;
            cursor: pointer;

            .queue-name {
                display: flex;
                height: 24px;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;

                .name-text {
                    font-size: 15px;
                    font-weight: 800;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 150px;
                }

                .queue-date {
                    display: flex;
                    flex-direction: row;

                    .date {
                        display: flex;
                        align-items: center;
                        padding: 0px 31px;
                        background-color: #eaf3fd;
                        border-radius: 18px;
                        height: 24px;

                        .date-text {
                            color: #a8b0e3;
                            font-size: 12px;
                        }
                    }

                    .icon {
                        color: #4d5bd8;
                        margin-left: 10px;
                    }
                }
            }

            .queue-id {
                display: flex;
                height: 24px;
                align-items: center;

                .queue-id-text {
                    color: #eaebf8;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .bottom-half {
            display: flex;
            height: 56px;
            flex-direction: row;
            background: #fff;
            flex-direction: row;
            align-items: center;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            z-index: 2;

            .order-text {
                margin-left: 20px;
                font-size: 12px;
                font-weight: 600;
            }

            .order-number {
                margin-left: 10px;
                color: #ff7e00;
                font-size: 12px;
                font-weight: 600;
                margin-right: 20px;
            }

            .order-number_complete {
                margin-left: 10px;
                color: #1fc3a5;
                font-size: 12px;
                font-weight: 600;
                margin-right: 20px;
            }

            .price-number {
                margin-left: 10px;
                color: #4d5bdb;
                font-size: 12px;
                font-weight: 600;
            }

            .all-btn {
                border-radius: 20px;
                height: 20px;
                padding: 0 16px;
                font-size: 12px;
                margin-left: 15px;
                background-color: #4d5bdb;
                color: #fff;
            }
        }

        .mid {
            border: 0.5px solid #ebeff6;
        }
    }

    .order-com {
        width: 368px;
        display: flex;
        flex-direction: column;
        border: 1px solid #e1e6ed;
        margin-top: -10px;
        border-top: none;
        background-color: #f6faff;

        .icons {
            display: flex;
            justify-content: flex-end;
            margin-right: 5px;
            margin-top: 20px;
        }

        .order-list {
            padding: 10px 5px;

            .order-detail {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;

                .order-icon {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .icons-group {
                        display: flex;
                        flex-direction: column;
                        height: 104px;
                        background: #e6eff9;
                        border-radius: 25px;
                        align-items: center;
                        justify-content: space-around;
                        width: 100%;
                        margin-top: 10px;

                        .icon {
                            color: #9da3d9;
                            cursor: pointer;
                        }
                    }
                }
            &.disabled{
                opacity: .8;
            }
            
            }

        }
    }
}


.order-info {
    border: 1px solid #D1E0F2;
    width: 313px;
    // height: 168px;
    margin-left: 5px;
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff;

    .details {
        display: flex;
        width: 100%;
        background-color: #e6eff9;
        height: 63px;
        justify-content: space-between;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        .order-name {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            margin-left: 15px;
            width: 50%;

            .name {
                font-size: 12px;
                font-weight: 800;
                color: black;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                // width: 70%;
            }

            .id {
                font-size: 12px;
                font-weight: 600;
                color: #4d5bd8;
            }
        }

        .order-date {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;

            .date {
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 500;
                padding: 0px 10px;
                background-color: #d1e0f2;
                border-radius: 25px;
                height: 24px;
                text-align: center;
                color: #515973;
                overflow: hidden;
                // display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
        }
    }

    .customer-info {
        text-align: left;
        flex-direction: column;
        background-color: #fff;
        // height: 105px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .amount-paid {
            .price {
                color: #6dd8c5;
                font-size: 12px;
                margin-left: 16px;
            }

            .not_payment {
                color: #ff7b43;
                font-size: 12px;
                margin-left: 16px;
            }
        }

        .m16 {
            display: flex;
            flex-direction: row;
            margin-left: 15px;
            align-items: center;
            margin-top: 8px;
            margin-bottom: 8px;
        }

        .m15 {
            font-size: 12px;
            align-items: center;
            margin-left: 16px;
            color: #737ca8;
        }

        .text-style {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            width: 80%;
        }
    }

    .order-info-icon {
        color: #4B5DBD;
        font-size: 18px;
    }
}

.queue-historical {
    padding: 24px 32px;
    height: 80vh;

    .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .left-div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .title {
                font-size: 15px;
                font-weight: 700;
                margin-left: 20px;
            }

            .icon {
                color: #ff7b43;
            }
        }

        .right-div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .clear-icon {
                color: #b7b7b7;
                margin-left: 20px;
            }

            .date-queue {
                display: flex;
                height: 40px;
                border: 1px solid #e7ecf4;
                border-radius: 88px;
                width: 125px;
                justify-content: center;
                align-items: center;
                margin-right: 20px;

                // padding-left: 10px;
                // margin-top: 8px;
                // margin-bottom: 8px;
                .MuiInput-underline:before {
                    border: none;
                }

                .MuiFormControl-marginNormal {
                    margin-top: 8px;
                    margin-bottom: 8px;
                }

                .MuiInputBase-input {
                    color: #8fa4c2;
                    font-size: 13px;
                    font-weight: 600;
                    width: 80px;
                }

                .MuiSvgIcon-root {
                    color: #8fa4c2;
                }

                .MuiIconButton-root {
                    padding: 0px 10px 0px 0px;
                }
            }
        }
    }

    .date {
        font-size: 13px;
        color: #bfc0c3;
        font-weight: 600;
    }

    .text {
        color: #4d5bd8;
    }

    .textTime {
        color: #4d5bd8;
        border-radius: 55px;
        background-color: rgba(234, 243, 253, 1);
        color: #4d5bd8;
        padding: 4px 10px;
    }

    .orangeBtn,
    .orangeBtn:hover {
        height: 40px;
        padding: 0px 10px;
        margin: 8px 10px 8px 0px;
        background: rgba(255, 128, 88, 0.6);
        color: rgb(255, 255, 255);
        border-radius: 88px;
        text-transform: unset;
        text-align: center;
    }

    .orangeBtn:hover,
    .orangeBtn.active {
        background: rgb(255, 128, 88);
    }
}

.queue-order-info {
    // height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .queue-info {
        // height: 90px;
        // 信息框内容
        display: flex;
        flex-direction: row;
        background: #fff;
        -moz-box-shadow: 0px 4px 5px rgba(77, 91, 216, 0.117647058823529);
        -webkit-box-shadow: 0px 4px 5px rgba(77, 91, 216, 0.117647058823529);
        box-shadow: 0px 4px 5px rgba(77, 91, 216, 0.117647058823529);
        padding: 4px 0px;
        border-radius: 8px;

        .infoContent {
            display: flex;
            flex-direction: column;

            .name {
                color: black;
                font-weight: 600;
                font-size: 12px;
            }

            .time {
                color: #4d5bd8;
                font-weight: 600;
                font-size: 12px;
                margin-top: 6px;
            }
        }

        .queueInfoArrow {
            background: #fff;
        }

        .queueInfoArrow {
            content: "";
            height: 10px;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 10px;
        }

        .infoBtn {
            writing-mode: vertical-rl;
            text-align: center;
            width: 30px;
            font-size: 40px;
            line-height: 10px;
            border-left: 1px solid #dedcdc;
        }

        .index-order {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 30px;
            font-size: 15px;
            font-weight: 600;
            border-left: 1px solid #dedcdc;
        }
    }

    .order-processing {
        display: flex;
        margin-top: 7px;
        background: rgba(46, 58, 176, 0.4);
        justify-content: center;
        align-items: center;
        border-radius: 88px;
        padding: 5px;
        border: 1.5px solid #5560db;

        .circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 88px;
            background-color: #5560db;

            // margin-top: 7px;
            .deliveredCircle {
                font-weight: 500;
                color: #fff;
            }

            .conductCircle {
                font-weight: 500;
                color: #fff;
            }

            .notStartedCircle {
                font-weight: 500;
                color: #fff;
            }
        }
    }

    .not {
        .circle-not {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 88px;
            background-color: #5560db;
            margin-top: 7px;

            .deliveredCircle {
                font-weight: 500;
                color: #fff;
            }

            .conductCircle {
                font-weight: 500;
                color: #fff;
            }

            .notStartedCircle {
                font-weight: 500;
                color: #fff;
            }
        }
    }
}

.orderUpdatePopover {
    display: inline-block;

    .updateOrderBtn {
        min-width: 192px;
        background: rgb(77, 91, 216);
        font-size: 14px;
    }
}


.rider-component {
    padding: 20px;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;

    .rider-component-table {
        margin-bottom: 50px;
        height: 400px;
        overflow: auto;
    }

    .order-delivery-adjust {
        display: flex;
        margin-top: 20px;
        height: calc(100vh - 260px);

        .order-delivery-adjust-right {
            flex: 1;
            min-width: 400px;
            height: calc(100vh - 260px);
            position: relative;

            .order-delivery-adjust-right-icon {
                position: absolute;
                background: #fff;
                border-radius: 50%;
                color: #5560db;
                width: 36px;
                height: 36px;
                min-width: 36px;
                min-height: 36px;
                z-index: 2;
                bottom: 32px;
                left: 16px;
                :hover{
                    opacity: .8;
                }
            }
        }

        .order-delivery-adjust-left {
            height: calc(100vh - 260px);
            // width: 363px;
            overflow: auto;

        }

        .order-delivery-adjust-order {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

        }

        .order-delivery-adjust-icon {
            width: 36px;
            height: 36px;
            font-size: 22px;
            border-radius: 50%;
            color: rgb(180, 196, 238);
            background: rgb(231, 238, 247);
            border: 0.5px solid rgb(209, 224, 242);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .order-delivery-adjust-left-bottom {
            flex: 1;
            overflow: auto;
        }
    }

    .rider-component-top {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 2;
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .cancel {
            min-width: 162px;
            border-radius: 88px;
            background: #f1f4f8;
            color: #4d5bd8;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .confirm {
            min-width: 162px;
            border-radius: 88px;
            color: #fff;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .b4d {
            background-color: #4d5bdb;
        }

        .bc3 {
            background-color: #c3c4c7;
        }
    }
}