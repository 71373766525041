.marketing-component {
    text-align: left;
    padding: 40px 20px;
    .top {
        display: flex;
        justify-content: space-between;
        .text {
            font-size: 32px;
            font-weight: bold;
        }
    }
}

.add-market {
    margin: 20px;
    min-width: 600px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    .add-top {
        display: flex;
        justify-content: space-between;
    }
    .save-btn {
        display: flex;
        justify-content: center;
        .btn-active {
            min-width: 120px;
            border: 1px solid #4d5bdb;
            border-radius: 88px;
            margin: 0px 5px;
            background-color: #4d5bdb;
            color: #fff;
            cursor: pointer;
        }
    }
    .rules {
        margin: 20px 0;
        background: #f1f4f8;
        border-radius: 10px;
        .source_title {
            font-size: 15px;
            color: #666292;
            padding: 0 16px;
            padding: 0 16px;
            flex: 0 0 auto;
            width: 150px;
        }
        .tags_title {
            font-size: 15px;
            color: #666292;
            padding: 0 16px;
            padding: 0 16px;
            flex: 0 0 auto;
        }
        .order-item {
            display: flex;
            justify-content: space-between;
            background: #fff;
            margin: 20px;
            flex-direction: row;
            padding: 0px 5px;
            align-items: center;
            border-radius: 10px;
            .order-rules {
                display: flex;
                flex-direction: column;
                width: 100%;
                .item {
                    display: flex;
                    flex-direction: row;
                    padding: 5px;
                    align-items: center;
                }
            }
        }
        .rules-avg-item {
            background: #fff;
            margin: 20px;
            height: 60px;
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 0px 5px;
            border-radius: 10px;
        }
        .rules-city-item {
            background: #fff;
            margin: 20px;
            height: 60px;
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 0px 5px;
            justify-content: space-between;
            border-radius: 10px;
            .city-com {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .btn-active {
                    display: -webkit-inline-box;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid #4d5bdb;
                    border-radius: 88px;
                    background-color: #4d5bdb;
                    color: #fff;
                    margin: 5px;
                    padding: 0px 5px;
                    .label-icon {
                        cursor: pointer;
                        color: #d9dee4;
                        padding-top: 2px;
                    }
                }
            }
        }
        .rules-item {
            background: #fff;
            margin: 20px;
            height: 50px;
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 0px 5px;
            border-radius: 10px;
            .btn-invalid {
                border: 1px solid #e7ecf4;
                border-radius: 88px;
                margin: 0px 5px;
                color: #4d5bdb;
                background-color: #fff;
                cursor: pointer;
                text-transform: unset;
            }
            .btn-active {
                border: 1px solid #4d5bdb;
                border-radius: 88px;
                margin: 0px 5px;
                background-color: #4d5bdb;
                color: #fff;
                cursor: pointer;
                text-transform: unset;
            }
        }
        .rules-label-item {
            background: #fff;
            margin: 20px;
            height: 200px;
            display: flex;
            flex-direction: column;
            padding: 0px 5px;
            border-radius: 10px;
            .label-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .laebl {
                padding: 20px;
                flex-wrap: wrap;
                overflow: auto;
                .label-number {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 20px;
                    .tags-text {
                        font-size: 15px;
                        color: #666292;
                    }
                }
                .btn-active {
                    display: -webkit-inline-box;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid #4d5bdb;
                    border-radius: 88px;
                    background-color: #4d5bdb;
                    color: #fff;
                    margin: 5px;
                    padding: 0px 5px;
                    .label-icon {
                        cursor: pointer;
                        color: #d9dee4;
                        padding-top: 2px;
                    }
                }
            }
        }
    }
}
.rules-google-map-dialog {
    min-width: 700px;
    .rightIcon {
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
        position: fixed;
        width: 700px;
        z-index: 9;
    }
    .google-map {
        height: calc(100vh - 64px);
    }
}
.res-label {
    margin: 20px;
    min-width: 600px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    .input-label-number {
        margin-top: 20px;
        .addLabel {
            margin-top: 10px;
            .btn {
                min-width: 120px;
                border: 1px solid #4d5bdb;
                border-radius: 88px;
                margin: 5px 5px;
                background-color: #4d5bdb;
                color: #fff;
            }    
        }
    }
    .label-top {
        display: flex;
        justify-content: space-between;
    }
    .labels {
        border: 1px solid #d6e0eb;
        border-radius: 12px;
        padding: 20px;
        flex-wrap: wrap;
        overflow: auto;
        margin-top: 20px;
        .btn-invalid {
            border: 1px solid #e7ecf4;
            border-radius: 88px;
            margin: 5px 5px;
            color: #4d5bdb;
            background-color: #fff;
            cursor: pointer;
            .num {
                margin-left: 6px;
                color: #333;
            }
        }
        .btn-active {
            border: 1px solid #4d5bdb;
            border-radius: 88px;
            margin: 5px 5px;
            background-color: #4d5bdb;
            color: #fff;
            cursor: pointer;
            .num {
                margin-left: 6px;
                color: #fff;
            }
        }
    }
    .btn {
        min-width: 120px;
        border: 1px solid #4d5bdb;
        border-radius: 88px;
        margin: 20px 5px;
        background-color: #4d5bdb;
        color: #fff;
        cursor: pointer;
    }
}
.sms-com {
    min-width: 500px;
    min-height: 350px;
    .sms-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 20px;
        .close-back {
            display: flex;
            flex-direction: row;
        }
    }
    .sms-content {
        padding: 20px;
        height: 100%;
    }
    .btn-send {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
        .btn-active {
            height: 40px;
            min-width: 120px;
            border: 1px solid #4d5bdb;
            border-radius: 88px;
            margin: 5px 5px;
            background-color: #4d5bdb;
            color: #fff;
            cursor: pointer;
        }
    }
}
.email-com {
    min-width: 500px;
    min-height: 350px;
    .email-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 20px;
        .close-back {
            display: flex;
            flex-direction: row;
        }
    }
    .sms-content {
        padding: 20px;
        height: 100%;
    }
    .btn-send {
        display: flex;
        justify-content: center;
        .btn-active {
            height: 40px;
            min-width: 120px;
            border: 1px solid #4d5bdb;
            border-radius: 88px;
            margin: 5px 5px;
            background-color: #4d5bdb;
            color: #fff;
            cursor: pointer;
        }
    }
}
.market-detail {
    margin: 20px;
    min-width: 600px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    .detail-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 20px;
    }

    .detail-content {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        border: 1px solid #d6e0eb;
        padding: 20px;
        background: #fff;
        justify-content: space-around;
        align-content: center;
        .text {
            text-align: center;
        }
    }
    .detail-condition {
        margin-top: 20px;
        border: 1px solid #d6e0eb;
        padding: 5px 15px;
        background: #fff;
        background: #f1f4f8;
        overflow: auto;
        .multiple-com {
            display: flex;
            flex-direction: row;
            background: #fff;
            justify-content: flex-start;
            padding: 0px 5px;
            margin: 10px 0px;
            .row {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                .item {
                    display: flex;
                    flex-direction: row;
                    margin: 5px 0px;
                }
            }
        }
        .label-item {
            flex-direction: column;
            background: #fff;
            justify-content: flex-start;
            padding: 0px 5px;
            margin: 10px 0px;
            min-height: 100px;
            flex-wrap: wrap;
            overflow: auto;
            .btn-active {
                border: 1px solid #4d5bdb;
                border-radius: 88px;
                margin: 10px 5px;
                background-color: #4d5bdb;
                color: #fff;
                height: 40px;
            }
            .label-number {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-top: 20px;
            }
        }
        .rule {
            display: flex;
            flex-direction: row;
            height: 55px;
            background: #fff;
            justify-content: flex-start;
            align-items: center;
            padding: 0px 5px;
            margin: 10px 0px;
            .btn-active {
                height: 40px;
                border: 1px solid #4d5bdb;
                border-radius: 88px;
                margin: 0px 5px;
                background-color: #4d5bdb;
                color: #fff;
                text-transform: unset;
            }
        }
    }
    .save-btn {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .btn-active {
            min-width: 120px;
            border: 1px solid #4d5bdb;
            border-radius: 88px;
            margin: 0px 5px;
            background-color: #4d5bdb;
            color: #fff;
            cursor: pointer;
        }
    }
}

.email-com::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
}

/*滚动条的轨道*/

.email-com::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #f1f4f8;
    border-radius: 5px;
    background: #fff;
}

/*滚动条里面小滑块*/

.email-com::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 5px #fff;
    background: #fff;
}
