.group-management-detail .title {
    margin-bottom: 0;
}
.group-management-detail .subtitle {
    font-size: 18px;
    margin-top: 10px;
}


.group-management-dialog .title {
    font-size: 18px;
    margin-bottom: 0;
}
.group-management-dialog .subtitle {
    font-size: 18px;
    color: #BFCAE8;
    margin-top: 10px;
}


.restaurant-management-component {
   padding-top: 32px;
}

.restaurant-management-component .title {
    font-size: 18px;
    margin-bottom: 24px;
}

.restaurant-detail {
    height: 600px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 20px;
}

.restaurant-detail .title {
    font-weight: 700;
    font-size: 18px;
    color: rgb(51, 51, 51);
}
.restaurant-detail .subTitle {
    font-size: 18px;
    font-weight: 500;
    color: #BFCAE8;
}

.restaurant-detail-content {
    overflow: auto;
    margin-bottom: 20px;
}

.restaurant-detail-content .commonCard {
    margin: 10px 0;
    border-radius: 8px;
    border: 1px solid rgb(201, 216, 239);
    box-shadow: 7px 10px 7px #F4F8FF;
}

.restaurant-detail-content::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
}

/*滚动条的轨道*/

.restaurant-detail-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #F1F4F8;
    border-radius: 5px;
    background: #FFF;
}

/*滚动条里面小滑块*/

.restaurant-detail-content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 5px #fff;
    background: #fff;
}