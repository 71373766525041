.restaurant_div {
    display: flex;
    flex-Direction: row;
    border-Radius: 8px;
    /* border-Width: 1px; */
    /* border-Style: solid; */
    margin-Top: 20px;
    width: 100%;
    background: #fff;

}

.restaurant_info_main {
    flex: 1;
    flex-Direction: column;
    overflow-Y: hidden;
    padding-Top: 20px;
    padding-Left: 10px;
    background: #F1F4F8;
}
.restaurant_save {
    display: flex;
    flex-Direction: row;
    justify-Content: space-between;
    margin-Top: 30px;
}
.restaurant_account {
    display: flex;
    flex-Direction: column;
    justify-Content: flex-start;
    align-Items: flex-start;
    overflow-Y: scroll;
    height: 80%;
    padding: 10px;
}
.restaurant_account_img  {
    display: inline-flex;
    justify-Content: center;
    align-Items: center;
    width: 25%;
}
.restaurant_account_phone {
    display: inline-flex;
    justify-Content: center;
    align-Items: center;
    width: 25%;
    flex-Direction: column;
}
.restaurant_account_phone_preflex {
    width: 100%;
    margin-Bottom: 26px;
    display: flex;
    flex-Direction: row;
    justify-Content: center;
}
.restaurant_authorization {
    display: flex;
    flex-Direction: column;
    width: 50%;
    margin-Bottom: 26px;
    justify-Content: center;
    align-Items: center;
}
.restaurant_info {
    width: 40%;
    display: flex;
    flex-Direction: column;
    justify-Content: center;
    align-Items: center;
}
.restaurant_info_text {
    width: 90%;
    margin-Top: 15px;
    display: flex;
    justify-Content: flex-start;
}
.restaurant_address_city {
    display: flex;
    width: 90%;
    flex-Direction: row;
    justify-Content: center;
    align-Items: center;
    margin-Top: 26px;
}
.restaurant_address_provice {
    display: flex;
    width: 90%;
    flex-Direction: row;
    margin-Top: 26px;
}
.restaurant_logo {
    width: 90%;
    margin-Top: 30px;
    display: flex;
    flex-Direction: column;
    align-Items: flex-start;
    justify-Content: flex-start;
}
.restaurant_week {
    width: 100%;
    margin-Top: 10px;
    display: flex;
    flex-Direction: column;
    justify-Content: center;
    align-Items: center;
}
.restaurant_week_open {
    display: flex;
    width: 75%;
    flex-Direction: row;
    height: 35px;
    overflow: hidden;
    text-Overflow: ellipsis;
    white-Space: nowrap;
    cursor: pointer;
    align-Items: center;
}
.restaurant_week_item {
    display: flex;
    justify-Content: flex-start;
    align-Items: center;
    flex-Direction: row;
    width: 90%;
    margin-Left: 5%;
}
.restaurant_tax_group {
    width: 100%;
    height: 600px;
    display: flex;
    flex-Direction: column;
    justify-Content: flex-start;
    align-Items: flex-start;
    margin-Bottom: 20px;
}
.restuarant_tax_info {
    width: 50%;
    display: flex;
    flex-Direction: column;
    justify-Content: center;
    align-Items: center;
    margin-Bottom: 20px;
}
.restaurant_info_concant {
    width: 60%;
    display: flex;
    flex-Direction: column;
    align-Items: center;
}