.memberInput {
    margin-top: 20px;
    width: 100%;
    display: flex;
}

.member-container {
    div:nth-child(2) .MuiSwitch-root {
        opacity: 0;
    }
}