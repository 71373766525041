// 按钮

// 白底按钮
.base-component .whiteBtn,
.base-component .whiteBtn:hover {
    color: #8fa4c2;
    background-color: #fff;
    border: 1px solid #e7ecf4;
}

.base-component .whiteBtn:hover {
    opacity: 0.8;
}

.base-component .commonBtn {
    border-radius: 20px;
    height: 42px;
    line-height: 40px;
    padding: 0 16px;
    font-size: 12px;
    min-width: 80px;
}

// 透明背景按钮
.base-component .black_fill_white_border_Btn,
.base-component .black_fill_white_border_Btn:hover {
    background-color: transparent;
    border: 1px solid #e7ecf4;
    color: #8fa4c2;
}

// 浅色带边框按钮
.base-component .white_border_Btn,
.base-component .white_border_Btn:hover {
    background-color: transparent;
    border: 1px solid #8fa4c2;
    color: #8fa4c2;
}

// 蓝底按钮
.commonBtn.blueBtn,
.commonBtn.blueBtn:hover {
    color: #fff;
    background-color: rgba(77, 91, 216, 1);
}

.commonBtn.blueBtn:hover {
    opacity: 0.8;
}

.commonBtn.blueBtn.disabled {
    opacity: 0.8;
    cursor: auto;
    color: #fff;
}

// 灰底按钮
.commonBtn.grayBtn,
.commonBtn.grayBtn:hover {
    color: #fff;
    background-color: #d9dde6;
}

.commonBtn.grayBtn:hover {
    opacity: 0.8;
}

// 又大又圆蓝色的图标按钮
.blue_round_icon {
    width: 40px;
    height: 40px;
    display: inline-flex;
    border-radius: 50%;
    background: #4d5bd8;
    color: #fff;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.blue_round_icon:hover {
    opacity: 0.85;
}

.blue_round_icon.disabled {
    background: #a9abae;
    cursor: auto;
}

.blue_round_icon.disabled:hover {
    opacity: 1;
}

// 状态筛选 chips
.base-component .statusAlert,
.base-component .statusAlert:focus {
    border-radius: 40px;
    color: #8fa4c2;
    background-color: rgba(244, 247, 254, 1);
    padding: 2px 10px;
    border: 1px solid rgba(231, 236, 244, 1);
}

// 图标按钮
.commonIconBtn {
    display: inline-flex;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
}

.commonIconBtn.whiteBtn:hover {
    background: rgba(231, 236, 244, 1);
}

.drop-pre {
    width: 280px;
    text-align: left;
}

.button-pre {
    position: relative !important;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0) !important;
    border: 1px solid rgba(255, 255, 255, 0) !important;
    border-radius: 3px 0 0 3px;
    width: 50px;
    height: 60px;
    padding-left: 10px;

    .selected-flag {
        margin-left: 10px;
    }
}

.reservation-pre {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f4f7fb !important;
    border: 1px solid rgba(255, 255, 255, 0) !important;
    border-radius: 3px 0 0 3px !important;

    .selected-flag {
        margin-left: 0 !important;
        width: 100%;
        min-width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        .flag {
            position: unset;
            display: flex;
            align-items: center;
            margin-top: unset;

            .arrow {
                margin-left: 20px;
                position: unset;
            }
        }
    }
}

.phone-pre {
    position: relative !important;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0) !important;
    border: 1px solid rgba(255, 255, 255, 0) !important;
    border-radius: 3px 0 0 3px;
    width: 100% !important;
    height: 55px;

    .selected-flag {
        display: flex;
        width: 100% !important;
        justify-content: center;
        padding: 0;
    }
}

.reservation_order_part {
    display: inline-flex;

    .selectSearch {
        display: flex;
        border: 1px solid #e7ecf4;
        border-radius: 20px;
        background: #fff;
        align-items: center;

        .searchSelsctValue {
            margin: 0;

            .MuiSelect-select.MuiSelect-select {
                width: auto;
                border-radius: 0 20px 20px 0;
                border: none;
            }
        }

        .datePicker {
            .commonBtn {
                border-radius: 0 20px 20px 0;
                height: 40px;
                line-height: 40px;
                padding: 0 16px;
                font-size: 12px;
                margin-left: 0;
            }

            .whiteBtn,
            .whiteBtn:hover {
                color: #8fa4c2;
                background-color: #fff;
            }
        }
    }

    .selectinput {
        display: inline-flex;

        .MuiFormControl-root {
            margin-left: 0;

            .MuiSelect-select.MuiSelect-select {
                width: auto;
                border-radius: 20px 0 0 20px;
                border: none;
                border-right: 1px solid #e7ecf4;
            }
        }
    }

    .phoneInput {
        width: 40px;
        height: 40px;
        align-items: center;
        display: flex;
        background: #fff;

        .react-tel-input {
            background: #fff !important;
            height: 40px;
        }

        .phone-pre {
            height: 40px;
        }
    }
}

.reservation_order_chip {
    flex-shrink: 0;
    text-align: left;
    padding: 0 8px 8px;

    // height: 56px;
    .statusAlert,
    .statusAlert:focus {
        margin-top: 10px;
        border-radius: 40px;
        color: #8fa4c2;
        background-color: rgba(244, 247, 254, 1);
        padding: 2px 10px;
        border: 1px solid rgba(231, 236, 244, 1);
        max-width: 600px;
    }
}

.baseComponent {

    .statusAlert,
    .statusAlert:focus {
        margin-top: 10px;
        border-radius: 40px;
        color: #8fa4c2;
        background-color: rgba(244, 247, 254, 1);
        padding: 2px 10px;
        border: 1px solid rgba(231, 236, 244, 1);
        max-width: 600px;
    }
}


.commonDialog {
    .dialogTop {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dialogTitle {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
        }
    }

    .footerBtn {
        justify-content: center;
        padding: 20px;

        .cancelBtn,
        .confirmBtn {
            border-radius: 55px;
            width: 162px;
            height: 40px;
        }

        .cancelBtn {
            background-color: #f1f4f8;
            color: #4d5bd8;
        }

        .confirmBtn {
            background-color: #4d5bd8;
            color: #fff;
        }
    }

    .basicInfoCard .dateTimePickerInput {
        padding: 0;
        width: 100%;
    }

    .basicInfoCard .dateTimePickerInput label+.MuiInput-formControl {
        margin-left: 10px;
    }
}

.datePickerInput {
    input {
        color: #8fa4c2;
        width: 100px;
    }

    button {
        color: #8fa4c2;
    }

    fieldset {
        border-color: rgba(231, 236, 244, 1);
        border-radius: 60px;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: rgba(231, 236, 244, 1);
    }
}