.google-map {
    display: flex;
    height: 100vh;
    overflow: hidden;
    .content {
        width: 30%;
        height: 100%;
        text-align: left;
        z-index: 2;
        background: #fff;
        .nearbyResultUl {
            width: 100%;
            height: calc(100% - 110px);
            overflow-y: scroll;
            overflow-x: hidden;
            padding-left: 0;
            margin-top: 0;
        }
        .currentLoactionItem .name {
            color: #05a5ff;
            font-weight: bold;
            font-size: 18px;
        }
        .currentLoaction {
            height: 50px;
            margin-top: 50px;
            margin-bottom: 10px;
        }
    }

    .item {
        padding: 5px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 45px;
            cursor: pointer;
            .boxicon {
                width: 60px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .boxtext {
                width: calc(100% - 60px);
            }
        }
        p {
            width: 90%;
            padding: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
        }
        .name {
            font-size: 16px;
            color: #000;
        }
        .address {
            color: #686868;
            height: 20px;
        }
    }
    .box:hover {
        .name {
            color: #05a5ff;
        }
    }
    .centericon {
        width: 60px;
        height: 60px;
        position: absolute !important;
        z-index: 1000;
        top: calc(50% - 60px);
        left: calc(50% - 30px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .buttonicon {
        background-color: white;
        border-radius: 3px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
    .SearchBox {
        width: 100%;
        height: 60px;
        margin-top: 50px;
        position: absolute;
        display: flex;
        justify-content: center;
    }
    .SearchBoxinput {
        box-sizing: border-box;
        border: 1px solid transparent;
        width: 80%;
        height: 60px;
        padding: 0 30px;
        border-radius: 5px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        font-size: 18px;
        outline: none;
    }
    /* WebKit, Blink, Edge */
    ::-webkit-input-placeholder {
        padding-left: 15px;
    }
    .buttonclose {
        height: 60px;
        width: 10%;
        box-sizing: border-box;
        background-color: white;
        border-radius: 5px;
        margin-right: 2%;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        border: 0px solid transparent;
        user-select: none;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    // .gmnoprint a,
    // .gmnoprint span {
    //     display: none;
    // }
    // .gmnoprint div {
    //     background: none !important;
    // }
    // #circle-example div div a div img {
    //     display: none;
    // }
    ::-webkit-scrollbar {
        display: block;
    }
    ul {
        padding-left: 0;
    }
}

.google-map-dialog {
    overflow: hidden;
    .rightIcon {
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
        position: fixed;
        width: 100%;
        z-index: 9;
    }
}
.pac-container {
    z-index: 9999;
}
