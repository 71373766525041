.whatsapp-setting {
    width: 400px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid rgb(201, 216, 239);
    padding: 24px;

    .whatsapp-setting-switch {
        margin-top: 10px;
    }
}

.whatsapp-message-list {
    height: auto;
    margin-top: 20px;
    padding: 20px;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid rgb(201, 216, 239);
    box-shadow: rgb(244, 248, 255) 7px 10px 7px;

    .top-bar-mode {
        margin-right: 0;
    }
}

.whatsapp-message-detail{
    min-width: 200px;
    padding-bottom: 20px;
}