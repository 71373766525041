.formIconButton.imgLibraryBtn {
    color: #4191ff;
    width: 40px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    background-color: #4191ff26;
    min-width: 40px;
}

.imgLibraryDialog {
    .imgList {
        flex-wrap: wrap;

        .imgBox {
            height: 100px;
            width: 150px;
            margin-right: 8px;
            margin-bottom: 8px;
            position: relative;
            overflow: hidden;
            border: 1px solid #eee;
            border-radius: 10px;
            overflow: hidden;
            flex-shrink: 0;
        }

        .imgBoxIcon {
            z-index: 1;
            position: absolute;
            color: rgb(45, 204, 86);

        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    p {
        word-break: break-all;
    }
}