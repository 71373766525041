.analytics-component {
    padding: 40px 20px;
    text-align: left;
    overflow: auto;
    .title {
        font-size: 32px;
        font-weight: "bold";
    }
    .title-filter {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        margin-left: -15px;
        .title-filter-date {
            display: flex;
            border: none;
            justify-content: center;
            background: #fff;
            border-radius: 88px;
            width: 120px;
            margin-left: 20px;
            color: #8fa4c2;
        }
    }
    .filter-select {
        margin-top: 10px;
    }
    .statusAlert {
        margin-top: 16px;
        border-radius: 40px;
        color: #8fa4c2;
        background-color: rgba(244, 247, 254, 1);
        padding: 2px 10px;
        border: 1px solid rgba(231, 236, 244, 1);
        margin-left: 8px;
    }
    .dateAlert {
        width: 420px;
    }
    .dish-popular {
        width: 60%;
        border: 1px solid #e7ecf4;
        border-radius: 12px;
        padding: 20px;
        background: #fff;
        margin-top: 16px;
    }
    .order-total-analytics {
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        .order-total {
            flex-direction: column;
            width: 20%;
            min-width: 200px;
            .order {
                display: flex;
                flex-direction: column;
                padding: 20px;
                border: 1px solid #e7ecf4;
                border-radius: 12px;
                text-align: left;
                background: #fff;
                .order-title {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
    .delivery-analytics {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 30%;
        margin-left: 16px;
        background: #fff;
        border-radius: 12px;
        border: 1px solid #e7ecf4;
        height: 200px;
        padding: 20px 20px 10px 20px;
        min-width: 400px;
        .detail-data {
            display: flex;
            flex-direction: column;
            width: 50%;
            .round-or-data {
                display: flex;
                flex-direction: row;
                align-items: center;
                // margin-top: 20px;

                .round-number {
                    width: 20px;
                    height: 20px;
                    border-radius: 88px;
                    background: #ffca28;
                    margin-right: 10px;
                }
                .data {
                    display: flex;
                    flex-direction: column;
                    .number {
                        color: #40ca00;
                    }
                    .text-round {
                        color: #8fa4c2;
                    }
                }
                .round-pick {
                    width: 20px;
                    height: 20px;
                    border-radius: 88px;
                    background: rgb(112, 201, 47);
                    margin-right: 10px;
                }
                .dine-in {
                    width: 20px;
                    height: 20px;
                    border-radius: 88px;
                    background: rgb(189, 21, 80);
                    margin-right: 10px;
                }
                .take-away {
                    width: 20px;
                    height: 20px;
                    border-radius: 88px;
                    background: rgb(233, 127, 2);
                    margin-right: 10px;
                }
            }
        }
    }
}

.main-info {
    display: flex;
    flex: 1;
    overflow: hidden;
    height: 100vh;
    background: #f1f4f8;
    .scroll-div {
        width: 250px;
        background: #fff;
        overflow-y: hidden;
        padding-bottom: 96px;
        transition: width 0.3s;
        flex: 0 0 auto;
        .div-info {
            flex: 1;
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;
            .account-info-main {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 10px;
                .account-name {
                    margin-top: 8px;
                    font-family: "Krub Bold, Krub Regular, Krub";
                    font-size: 13px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 90%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .text-user {
                        color: #333;
                        font-weight: bold;
                    }
                    .text-email {
                        color: #a3b4cd;
                        font-size: 12px;
                        margin: 5px 0;
                    }
                    .text-restaurant {
                        color: #a3b4cd;
                        font-size: 12px;
                        padding: 0 10px;
                        border: 1px solid #a3b4cd;
                        max-width: 180px;
                        border-radius: 10px;
                    }
                }
                .account-email {
                    height: 16px;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    color: #d9dde6;
                }
            }
        }
    }
    .scroll-div::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 0px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 0px;
    }

    .dialog-btn-main {
        margin-top: 20px !important;
        height: 40px !important;
        width: 160px !important;
        background: #4d5bd8 !important;
        margin-bottom: 20px !important;
        color: #fff !important;
    }

    .upgrade-div {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 24px;
        bottom: 23px;
        height: 40px;
        background: #4d5bd8;
        border-radius: 8px;
        padding: 0px 10px;
        .text {
            display: flex;
            flex-direction: row;
            height: 100%;
            align-items: center;
            cursor: pointer;
            .text-phiz {
                color: #fff;
                padding-bottom: 3px;
            }
            .text-content {
                color: #fff;
                margin-left: 13px;
                margin-right: 13px;
                margin-bottom: 3px;
            }
        }
        .text-delete {
            color: #38429e;
        }
    }
    .upgrade-ArrowDropDown {
        display: flex;
        position: absolute;
        right: 24px;
        bottom: 0px;
        height: 40px;
        color: #4d5bd8;
        border-radius: 8px;
        padding: 0px 10px;
        font-size: 50px;
    }
    .scan-code {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        background: rgba(187, 187, 187, 0.5);
        z-index: 9999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .backdrop {
            width: 400px;
            height: 300px;
            background: #fff;
            display: flex;
            // justify-content: center;
            flex-direction: column;
            .top {
                display: flex;
                justify-content: flex-end;
                text-align: right;
                padding: 10px;
            }
            .text {
                font-size: 25px;
                margin-top: 70px;
            }
        }
    }
}

.index-badge {
    width: 100%;
    .MuiBadge-badge {
        color: #fff;
        padding: 12px;
    }
    .MuiBadge-colorSecondary {
        color: #fff;
        padding: 12px;
        border-radius: 88px;
    }
    .MuiBadge-anchorOriginTopRightRectangle {
        transform: scale(0.8) translate(0%, 40%);
    }
}

.index-child-badge {
    width: 100%;
    .MuiBadge-badge {
        color: #fff;
        padding: 12px;
    }
    .MuiBadge-colorSecondary {
        color: #fff;
        padding: 12px;
        border-radius: 88px;
    }
    .MuiBadge-anchorOriginTopRightRectangle {
        transform: scale(0.8) translate(0%, 40%);
    }
}

.icon-img {
    width: 26px;
    height: 26px;
}

.home-component {
    background-color: #f1f4f8;
    height: 100vh;
    overflow-y: auto;
    .top-bar {
        padding: 15px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .group-name {
            font-weight: bold;
            font-size: 30px;
            color: black;
        }
        .account-setting {
            .account {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .name {
                    color: #526f9b;
                }
                .img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 15px;
                    object-fit: cover;
                }
            }
            .setting {
                position: absolute;
                top: 70px;
                right: 48px;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 249px;
                height: 200px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dad8d8;
                border-radius: 10px;
                box-shadow: 0 10px 20px rgba(181, 187, 198, 0.25098);
                transition: all 0.2s ease-in;
                overflow: hidden;
                z-index: 2;
                .close {
                    text-align: right;
                    margin-top: 10px;
                    width: 220px;
                }
                .content-1 {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 0 15px 15px 15px;
                    .img {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                    .name {
                        margin: 15px 0;
                    }
                    .btn {
                        width: 141px;
                        height: 48px;
                        background: #4265ed;
                        border-radius: 8px;
                        color: #fff;
                        margin-top: 10px;
                    }
                    .out {
                        background: #27d086;
                    }
                }
            }
        }
    }
    .res-component {
        // background: #f1f4f8;
        display: flex;
        flex-direction: column;
        margin: 10px 40px;
        border-radius: 12px;
        align-items: flex-start;
        padding: 20px;
        .name {
            color: black;
        }
        .card-com {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            min-width: 200px;
            min-height: 220px;
            padding: 10px;
            .res-card:hover {
                transform: scale(1.01) translate(0%, 5px);
                box-shadow: rgba(218, 216, 216, 0.4) 0.5px 0.5px 3px 3px;
            }
            .res-card {
                position: relative;
                display: flex;
                align-items: center;
                width: 230px;
                height: 120px;
                background: inherit;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #e4e0e0;
                border-radius: 12px;
                margin-right: 24px;
                margin-top: 10px;
                padding: 8px;
                cursor: pointer;
                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    background: #4d5bd8;
                    color: #fff;
                    border-radius: 50%;
                    margin: 16px;
                }
                .text {
                    color: #8fa4c2;
                    text-align: left;
                    overflow: hidden;
                    font-weight: 600;
                    font-size: 18px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
                .group-name {
                    word-break: break-word;
                    color: #a3b4cd;
                    font-size: 12px;
                    border-radius: 10px;
                    margin: 8px 0px;
                    text-align: left;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }
        }
    }
    .features-component {
        background: #f1f4f8;
        display: flex;
        height: 40%;
        margin: 30px 40px 0 40px;
        border-radius: 12px;
    }
}

.authorization-com {
    background-color: #f1f4f8;
    // padding: 20px;
    height: calc(100% - 40px);
    .top-bar-back {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 30px 30px 0 30px;
    }
    .group-set-com {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 40px;
        .group-top-bar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 95%;
        }
        .group-point-com {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .group-point-sum {
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                min-width: 80px;
                min-height: 80px;
                padding: 10px;
                .point-arr {
                    display: flex;
                    flex-direction: column;
                    background: #4d5bdb;
                    border-radius: 10px;
                    height: 198px;
                    width: 186px;
                    margin: 10px;
                    cursor: pointer;
                    box-shadow: 7px 7px 1px 1px #8099f1;
                }
                .point {
                    display: flex;
                    flex-direction: column;
                    background: #4d5bdb;
                    border-radius: 10px;
                    height: 198px;
                    width: 186px;
                    margin: 10px;
                    cursor: pointer;
                }
            }
            .group-point {
                position: relative;
                display: flex;
                flex-direction: column;
                min-width: 80px;
                min-height: 80px;
                padding: 10px;
                .date-point {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .point-com {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        .point {
                            display: flex;
                            flex-direction: column;
                            background: #4d5bdb;
                            border-radius: 10px;
                            height: 198px;
                            width: 186px;
                            margin: 10px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .res-set-com {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 40px;
        .res-com {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 95%;
            .res-top-bar {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
        .res-point-com-sum {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            min-width: 80px;
            min-height: 80px;
            padding: 10px;
            .point-com-sum-arr {
                display: flex;
                flex-direction: column;
                background: #4d5bdb;
                border-radius: 10px;
                height: 198px;
                width: 186px;
                margin: 10px;
                cursor: pointer;
                box-shadow: 7px 7px 1px 1px #8099f1;
            }
            .point-com-sum {
                display: flex;
                flex-direction: column;
                background: #4d5bdb;
                border-radius: 10px;
                height: 198px;
                width: 186px;
                margin: 10px;
                cursor: pointer;
            }
        }
        .res-point-com {
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 80px;
            min-height: 80px;
            padding: 10px;
            .date-point {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .point-com {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .point {
                        display: flex;
                        flex-direction: column;
                        background: #4d5bdb;
                        border-radius: 10px;
                        height: 198px;
                        width: 186px;
                        margin: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .top-bar {
        padding: 10px 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .top-bar-left {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .top-bar-right {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            border-radius: 24px;
            height: 40px;
            margin-right: 20px;
            min-width: 200px;
            .line {
                width: 1px;
                height: 40px;
                border-bottom-color: #fff;
                background: #bfcae8;
            }
            .mode-right {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                background: #fff;
                border: 1px solid #bfcae8;
                border-top-right-radius: 24px;
                border-bottom-right-radius: 24px;
                min-width: 90px;
                .icom-text {
                    color: #8fa4c2;
                }
                cursor: pointer;
            }
            .mode-left {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                background: #fff;
                border: 1px solid #bfcae8;
                border-top-left-radius: 24px;
                border-bottom-left-radius: 24px;
                min-width: 90px;
                .icom-text {
                    color: #8fa4c2;
                }
                cursor: pointer;
            }
            .selected-mode-left {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid #ff8058;
                // border-radius: 24px;
                border-top-left-radius: 24px;
                border-bottom-left-radius: 24px;
                padding: 10px;
                background: #ff8058;
                min-width: 90px;
                .icom-text {
                    color: #fff;
                }
                cursor: pointer;
            }
            .selected-mode-right {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid #ff8058;
                // border-radius: 24px;
                border-top-right-radius: 24px;
                border-bottom-right-radius: 24px;
                padding: 10px;
                background: #ff8058;
                min-width: 90px;
                .icom-text {
                    color: #fff;
                }
                cursor: pointer;
            }
        }
    }
}
.authorization-com.authorization-com-loading {
    overflow: hidden;
    height: 100vh;
}
.point:hover {
    transform: scale(1.02) translate(0%, 0px);
}
.point-arr:hover {
    transform: scale(1.02) translate(0%, 0px);
}
.point-com-sum-arr:hover {
    transform: scale(1.02) translate(0%, 0px);
}
.point-com-sum:hover {
    transform: scale(1.02) translate(0%, 0px);
}
.card {
    display: flex;
    flex-direction: column;
    .info-bar {
        display: flex;
        width: 186px;
        height: 128px;
        background: #4d5bd8;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        flex-direction: column;
        .card-text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            color: #fff;
            font-weight: bold;
            padding: 20px;
            width: 148px;
            height: 47px;
            word-wrap: break-word;
            overflow: hidden;
            text-align: center;
        }
        .selected-card {
            display: flex;
            justify-content: flex-end;
            .circle_selected {
                display: flex;
                justify-content: center;
                align-content: center;
                border-radius: 88px;
                width: 24px;
                height: 24px;
                background: #6a86f1;
                margin-right: 5px;
                margin-top: 5px;
                cursor: pointer;
                margin-left: 5px;
            }
            .circle {
                display: flex;
                justify-content: center;
                align-content: center;
                border-radius: 88px;
                width: 24px;
                height: 24px;
                background: #b1c3ff;
                margin-right: 5px;
                margin-top: 5px;
                cursor: pointer;
                margin-left: 5px;
            }
        }
    }
    .tail-bar {
        display: flex;
        width: 186px;
        height: 70px;
        border-radius: 12px;
        background: #2e22a5;
        .rule {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 5px;
            .point-date {
                display: flex;
                flex-direction: row;
                width: 186px;
                justify-content: flex-start;
                align-items: center;
                .date {
                    color: #a2b6ff;
                    font-size: 12px;
                    margin-left: 5px;
                }
            }
        }
    }
}
