.delivery-setting {
    display: flex;
    height: 100vh;
    overflow: auto;
    .leftBox {
        width: 60%;
        padding: 30px;
    }
    .rightBox {
        width: 40%;
        padding: 30px 30px 0;
        overflow: auto;
        min-width: 400px;
        .checkoutHistorySwitch {
            width: 60px;
            height: 30px;
            padding: 1px;
            border-radius: 88px;
            .icon {
                // 
                
                border-radius: 88px;
                width: 26px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .MuiSwitch-switchBase {
                top: 1px;
                left: 2px;
            }
        }
        .open {
            .MuiButtonBase-root.MuiIconButton-root {
                color: #fff;
                transform: translateX(28px);
            }
            .MuiSwitch-track {
                background-color: #4d5bd8;
            }
            
        }
        .open_edit {
            .MuiButtonBase-root.MuiIconButton-root {
                color: #fff;
                transform: translateX(28px);
            }
            .MuiSwitch-track {
                background-color: #A3AAEC;
            }
        }
        .card-info {
            overflow: auto;
            min-width: 400px;
            min-height: 300px;
        }
        .cardTitle {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
        }
        .levelDeliveryCard {
            cursor: pointer;
        }
    }
    .formTip {
        text-align: left;
        font-size: 12px;
        color: #aab9dd;
        margin-left: 8px;
    }
}
.textAlignLeft {
    text-align: left;
}
.textAlignRight {
    text-align: right;
}
.label {
    color: #aab9dd;
    margin-right: 8px;
    margin-left: 8px;
}
.flexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.badge {
    min-width: 400px;
    .MuiBadge-badge {
        color: #fff;
        padding: 12px;
    }
    .MuiBadge-colorSecondary {
        color: #fff;
        padding: 12px;
        border-radius: 88px;
    }
}
.closed-calendar {
    border: 1px solid #4d5bdb;
    border-radius: 4px;
    width: 60%;
    min-width: 420px;
    .top-bar {
        display: flex;
        padding: 15px;
        justify-content: space-between;
        .left-top {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            .top-text {
                margin-left: 10px;
                font-size: 14px;
                color: #575988;
            }
        }
    }
    .line {
        height: 0.7px;
        border: 0.7px solid #d9d9da;
        border-bottom-color: #fff;
        background: #d9d9da;
    }
    .calendar {
        .DayPicker-Months {
            background: #fff;
        }
        .DayPicker-Day {
            padding: 0em;
            border-radius: 0px;
        }
        .DayPicker-Caption {
            color: #4d5bd8;
        }
        .DayPicker-wrapper {
            border: none;
            outline: none;
        }
    }
    .closed-text {
        margin: 20px;
        display: flex;
        flex-direction: column;
        .date {
            color: #4b5dbd;
            margin-left: 8px;
        }
    }
    .closed-event {
        margin-top: 20px;
        .date-event {
            display: flex;
            align-items: center;
            padding-left: 40px;
            padding-top: 20px;
            .date {
                color: #4b5dbd;
                margin-left: 8px;
            }
        }
        .checked-date {
            display: flex;
            align-items: center;
            padding-left: 30px;
            padding-top: 10px;
        }
        .checked-time {
            display: flex;
            align-items: center;
            padding-left: 30px;
        }
        .label-div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 30px;
            .label {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 26px;
                background: #eff2f7;
                color: #7d8a97;
                border-radius: 88px;
                padding-right: 15px;
                padding-left: 15px;
                font-size: 13px;
                margin-right: 15px;
                margin-bottom: 10px;
                cursor: pointer;
            }
            .select-label {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 26px;
                background: #4d5bdb;
                color: #fff;
                border-radius: 88px;
                padding-right: 15px;
                padding-left: 15px;
                font-size: 13px;
                margin-right: 15px;
                margin-bottom: 10px;
                cursor: pointer;
            }
        }
    }
    .calendar-btn {
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: space-evenly;
    }
}


// 外卖营业时间
.schedule-setting {
    position: relative;
    overflow: hidden;
    padding-bottom: 40px;

    .card-item {
        margin: 30px 40px;
        width: 50%;
        padding: 20px;
        background: rgb(255, 255, 255);
        border-radius: 8px;
        text-align: left;
    }
    .buttonPart {
        display: flex;
        align-items: center;
        .closeBtn {
            margin-left: 16px;
            min-width: 100px;
            color: #fff;
            background-color: #4d5bd8;
            border-radius: 20px;
            height: 43px;
            // line-height: 43px;
            padding: 0 16px;
            font-size: 12px;
        }
        .closeBtn.disabled {
            background-color: #c9d1e5;
        }
    }
}

.schedule-time-card {
    display: flex;
    flex-direction: column;
    min-width: 500px;
    padding: 20px;
    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .btn-group {
            display: flex;
            align-items: center;
        }
        .icon {
            cursor: pointer;
        }
        .title {
            font-size: 18px;
            font-weight: 700;
        }
    }
    .daily-business-hours {
        flex-direction: column;
        justify-content: flex-start;
        .day {
            font-size: 13px;
            font-weight: 500;
            text-align: left;
        }
        .range-hours {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            .hours {
                padding: 3px 20px;
                border: 1px solid #c1c2ce;
                border-radius: 88px;
                margin: 5px;
                cursor: pointer;
            }
            .hours-disable {
                padding: 3px 20px;
                border: 1px solid #c1c2ce;
                border-radius: 88px;
                margin: 5px;
                cursor: pointer;
                color: #fff;
                background-color: #c1c2ce;
            }
        }
    }
}
