.auth_admin_component {
    --width: clamp(200px, 45vmin, 500px);

    padding: 40px 24px 24px;
    text-align: left;
    .auth_admin_component_title {
        font-size: 32px;
        margin-bottom: 32px;
    }
    .auth_admin_component_chip {
        .statusAlert,
        .statusAlert:focus {
            margin-top: 16px;
            border-radius: 40px;
            color: #8fa4c2;
            background-color: rgba(244, 247, 254, 1);
            padding: 2px 10px;
            border: 1px solid rgba(231, 236, 244, 1);
        }
    }
    .autoWidthInput .MuiSelect-select {
        width: auto;
    }

    .collapse-content {
        margin: 25px 10px;

        .accordion-box {
            // 右边渐入动画
            .fade-in-right {
                opacity: 0;
                transform: translateX(100%);
                // animation: fade-in-right 0.4s ease-out forwards;
                animation: fade-in-right 0.18s ease-out both;
            }
        }

        @keyframes fade-in-right {
            to {
                opacity: 1;
                transform: translateX(0%);
            }
        }
    }

    .group-card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: 8px;
        justify-content: flex-start;
        .card {
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;
            margin-right: 6px;
            margin-top: 6px;
            perspective: 10px;
            cursor: pointer;
            transform: translateZ(0);
            transition-duration: 0.1s;
            transition-property: box-shadow, transform;

            .card-box-shadow {
                inset: 0;
                box-shadow:
                    calc(var(--width) * 0.006) calc(var(--width) * 0.008) calc(var(--width) * 0.02) 0 hsla(210, 10%, 100%, 0.9) inset,
                    calc(var(--width) * 0.01) calc(var(--width) * 0.01) calc(var(--width) * 0.04) 0 hsla(213, 13%, 23%, 0.2) inset;
            }
        }
    }
}