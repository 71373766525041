.discount-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    .title {
        font-weight: 600;
        font-size: 15px;
        color: #333333;
        display: flex;
        align-items: center;
    }

    .icon-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }
}

.business-hours {
    flex-direction: column;
    margin-top: 10px;

    .daily-business-hours {
        text-align: left;
        flex-direction: column;
        justify-content: flex-start;

        .day {
            font-size: 13px;
            font-weight: 500;
            text-align: left;
            color: #aab9dd;
        }

        .range-hours {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;

            .hours {
                padding: 3px 20px;
                border: 1px solid #aab9dd;
                border-radius: 88px;
                margin: 5px;
                color: #aab9dd;
            }
        }
    }
}