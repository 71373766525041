.menuItem {
    display: flex;
    align-items: center;
    padding: 16px 16px;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
}

.menuItemIcon {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuItemIcon.active {
    background-color: #4D5BD8;
}

.menuItemTitle {
    margin-left: 16px;
    color: rgb(116, 136, 167);
    text-align: left;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menuItem.active .menuItemTitle {
    color: #4D5BD8;
}

.collapsedMenu .menuItemTitle {
    display: none;
}

.subMenuItem .menuItem {
    display: flex;
    width: 100%;
    align-items: center;
    background: #F4F7FB;
    padding: 16px 16px;
    cursor: pointer;
}

.subMenuItem .menuItem.active .menuItemTitle {
    color: #4D5BD8;
}

.subMenuItem .otherMenu {
    padding: 4px 16px;
    cursor: auto;
    display: flex;
    justify-content: center;
    background: #FFF;
}

.subMenuItem .otherMenu .menuItemTitle {
    margin-left: 0;
    font-size: 14px;
}

.scroll-div .collapseBox {
    position: fixed;
    bottom: 0;
    width: 250px;
    z-index: 9;
    transition: width .3s;
    color: rgb(116, 136, 167);
    padding-bottom: 16px;
    background: #fff;
}

.scroll-div .collapseIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.scroll-div .collapseIcon svg {
    color: rgb(217, 221, 230);
    padding: 8px 16px 8px 16px;
}

.main-info .collapsedMenu,
.main-info .collapsedMenu .collapseBox {
    width: 80px;
}

.main-info .scroll-div.collapsedMenu .div-info .account-info-main,
.collapsedMenu .collapseText {
    display: none;
}

.collapseText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mainDrawList {
    flex: 1;
    overflow: auto;
}

.mainDrawList::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
}

.noVoucher {
    height: 90vh;
    width: 100%;
}

.mainBox {
    flex: 1 1 0%;
    overflow-x: hidden;
    min-width: 600px;
    position: relative;
}