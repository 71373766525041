.order_detail {
    overflow: hidden;
    display: flex;
}

.order_container {
    background: #fff;
    border-radius: 20px;
}

.order_detail_common {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-size: 14px;
    margin-left: 20px;
}

.order_detail_common .title {
    display: flex;
    align-items: center;
    flex: 2;
}

.title {
    display: flex;
    justify-content: space-between;
}

.order_detail_common .top {
    margin-bottom: 20px;
    position: relative;
    padding-right: 20px;
    margin-top: 20px;
    flex: none;
}

.order_detail_dialog .title {
    overflow: hidden;
}

.order_detail_common .content {
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
    flex: auto;
}

.order_detail_common .detail_id {
    color: #4D5BD8;
    font-size: 36px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

.order_detail .detail_id {
    max-width: 700px;
}

.order_detail_dialog .detail_id {
    max-width: 200px;
}

.order_detail_common .detail_name {
    color: #4D5BD8;
    font-size: 28px;
    font-weight: bold;
    margin-left: 16px;
}

.order_detail .formItem {
    text-align: left;
    width: 100%;
    display: inline-block;
    font-size: 14px;
}

.order_detail_common .formItem .content {
    background: rgb(214, 219, 225);
    border-radius: 10px;
}

.order_detail .formItem .content {
    min-height: 40px;
    margin: 10px;
    padding: 10px 20px;
}

.order_detail .formItem .label {
    color: rgb(32, 131, 247);
    white-space: nowrap;
}

.order_detail .cardTitle {
    font-size: 20px;
    margin-top: 20px;
}

.order_detail .cardContent {
    padding: 20px;
}

.order_detail_common .boxTitle {
    font-size: 15px;
    color: #77879E;
    display: inline-block;
}

.mt16 {
    margin-top: 16px;
}

.order_detail_common .menu {
    background-color: #f4f7fb;
    min-height: 20px;
    font-size: 15px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.grayBackground {
    background-color: #f4f7fb;
    border-radius: 10px;
    min-height: 40px;
    margin-top: 10px;
    padding: 10px 20px;
}

.order_detail_common .menuItem {
    display: flex;
    padding: 8px 16px;
    width: unset;
}

.order_detail_common .otherInfo {
    padding: 16px;
    border-top: 1px solid #dce5f3;
}

.order_detail_common .otherInfo .tip {
    color: #fff;
    background-color: #4d5bd8fe;
    border-radius: 14px;
    display: inline-block;
    padding: 4px 10px;
    margin-bottom: 10px;
    font-size: 12px;
    margin-right: 8px;
}

.order_detail_common .otherInfo .remark {
    color: #666292;
    background: #e8edf5;
    padding: 10px 10px;
    border-radius: 10px;
    min-height: 30px;
    line-height: 30px;
    word-break: break-all;
}

.order_detail_common .menuNum {
    flex: 2;
    color: #AAB9DD;
    text-align: center;
}

.order_detail_common .menuPrice {
    flex: 2;
    color: #666292;
}

.order_detail_common .menuName {
    flex: 4;
    color: #666292;
    display: flex;
    align-items: center;
}

.order_detail_common .titleDot {
    content: '';
    width: 5px;
    height: 5px;
    background: #666292;
    border-radius: 50%;
    margin-right: 5px;
}

.order_detail_common .titleLine {
    content: '';
    width: 5px;
    height: 1px;
    background: #666292;
    margin-right: 5px;
}

.order_detail_common .dishesCard {
    background-color: #f4f7fb;
    border-radius: 10px;
    padding: 10px 0;
}

.order_detail .footerBtn {
    display: flex;
    justify-content: space-between;
}

.order_detail_common .footerBtn {
    padding: 20px 40px 20px 0;
    flex: none;
}

.mb8 {
    margin-bottom: 8px;
}

.mt8 {
    margin-top: 8px;
}

.order_detail_dialog .footerBtn {
    text-align: center;
}

.order_detail_common .footerBtn button {
    margin-left: 16px;
    border-radius: 20px;
}

/* 设置滚动条的样式 */

::-webkit-scrollbar {
    width: 8px;
}

/* 滚动槽 */

::-webkit-scrollbar-track {
    box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

/* 滚动条滑块 */

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

.order_detail_dialog .MuiDialog-paperWidthSm {
    width: 530px;
    height: 670px;
}

.order_detail_dialog .label {
    color: #77879E;
    margin: 10px 0;
}

.order_detail_dialog .formItem .content {
    min-height: 30px;
    line-height: 30px;
    margin: 10px 0;
    padding: 10px 20px;
}

.order_cancel .cancelTop {
    display: flex;
    justify-content: space-between;
}

.order_cancel .cardTitle {
    color: #77879E;
    margin: 20px 10px;
    font-size: 16px;
}

.order_detail_common .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_detail_common .top .right {
    display: flex;
    align-items: center;
}

.order_detail_common .pupleDiv {
    color: #fff;
    background-color: #9091AA;
    padding: 0 10px;
    border-radius: 20px;
    margin-right: 10px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
}

.order_detail_common .menuLstItem .MuiTypography-root {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #8FA4C2
}

.dish-dialog .loadingBtn {
    color: #fff;
    width: 20px !important;
    height: 20px !important;
}

.order_detail_common .commonCard {
    padding: 24px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid #c9d8ef;
    box-shadow: 7px 10px 7px #f4f8ff;
}

.order_detail .phone-pre-new {
    margin: 0px 10px 18px;
}

.printOrder {
    font-size: 12px;
}

.printOrder p, td {
    line-height: 14px;
    margin: 0;
    padding: 0;
    word-break: break-all;
}

.printOrder p {
    font-size: 12px;
}

.printOrder td {
    font-size: 15px;
}

.mr8 {
    margin-right: 8px;
}

.line {
    border-bottom: 1px dashed #000;
    height: 1px;
    margin: 4px 0;
}

.textAlignCenter {
    text-align: center;
}

.printOrder p.bigFontSize {
    font-size: 18px;
    margin: 10px 0;
}

.printOrder td.bigFontSize {
    font-size: 16px;
    margin-bottom: 10px;
}

.order_detail .order-detail-dateInput {
    justify-content: center;
    padding: 0 8px;
    background: #F4F7FB;
    border-radius: 5px;
    height: 55px;
    width: 94%;
}

.order_detail .order-detail-dateInput label {
    top: 8px;
    left: 10px;
    color: rgb(170, 185, 221);
}

.order_detail .order-detail-dateInput label+.MuiInput-formControl {
    margin-top: 16px;
}

.order_detail .order-detail-dateInput .MuiInput-underline:before {
    border: none;
}

.order-filter {
    text-align: center;
    text-align: center;
    padding: 30px;
    overflow: hidden;
}

.price-total-details {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #f4f7fb;
    padding: 15px;
}

.price {
    display: flex;
    justify-content: space-between;
    margin: 5px;
}

.badgeNew {
    display: inline-block;
    background: rgb(255, 128, 88);
    font-size: 12px;
    color: #fff;
    padding: 3px 6px;
    border-radius: 20px;
}

.order_detail_common .top .badgeNew {
    position: absolute;
    right: 0;
}

.delLine {
    text-decoration: line-through;
}

.text-label {
    color: #AAB9DD;
}