.avatar-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: '#fff';

    .header-title-box {
        display: flex;
        align-items: center;
    }

    .header-title {
        font-size: 16px;
        font-weight: bold;
    }

    .header-close-icon {
        font-size: 26px;
        color: #000;
        cursor: pointer;
    }

    .header-action-box {
        display: flex;
        align-items: center;
    }

    .line {
        border-left: 2px solid #e8e8e8cc;
        height: 30px;
        margin-left: 8px;
        margin-right: 8px;
        border-radius: 20px;
    }
}
