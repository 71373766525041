.display-mode-btn {
    position: absolute;
    right: 10px;
    z-index: 1000;
    top: 4px;

    .display-mode-btn-root {
        width: 60px;
        height: 33px;
    }

    .display-mode-btn-switch.display-mode-btn-checked {
        transform: translateX(27px);
    }

    .display-mode-btn-thumb {
        width: 20px;
        height: 20px;
    }

    .display-mode-btn-icon {
        width: 28px;
        height: 28px;
        background: rgb(255, 255, 255);
        border-radius: 88px;
        margin-top: 1px;

        svg {
            padding-top: 3px;
            color: #D9DDE6;
        }
    }

    .display-mode-btn-checked {
        .display-mode-btn-icon {
            svg {
                color: #4D5BD8;
            }
        }
    }
}