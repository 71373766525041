.coupon-management {
    display: flex;
    flex-direction: column;
    background: #f1f4f8;
    padding: 40px 20px;
    position: relative;
    height: calc(100vh - 80px);
    .coupon-management-title {
        text-align: left;
        .coupon-management-title-btn-div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .coupon-management-title-btn {
                flex-direction: row;
                margin-top: 20px;
                .coupon-filter-selected-btn {
                    height: 35px !important;
                    background: #ff8058 !important;
                    color: #fff !important;
                    border-radius: 88px !important;
                    margin-left: 15px !important;
                    font-size: 13px !important;
                }
                .coupon-filter-btn {
                    height: 35px !important;
                    background: #fff !important;
                    color: #9aadc8 !important;
                    border-radius: 88px !important;
                    margin-left: 15px !important;
                    border: 1px solid #9aadc8 !important;
                    font-size: 13px !important;
                }
            }
        }
    }
    .coupon-management-card {
        display: flex;
        flex-direction: row;
        .coupon-add {
            display: flex;
            border: 1px solid #fff;
            width: 160px;
            height: 160px;
            background: #fff;
            justify-content: center;
            align-items: center;
            margin-top: 35px;
            margin-left: 20px;
            border-radius: 12px;
        }
        .coupon-card {
            display: flex;
            padding: 20px;
            flex-wrap: wrap;
        }
    }
}

.coupon-detail {
    text-align: left;
    padding-left: 20px;
    background: #f1f4f8;
    overflow-y: scroll;
    flex-direction: column;
    .title {
        font-size: 28px;
        font-weight: bold;
        color: black;
    }
    .title-component {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-component-text {
            display: flex;
            flex-direction: row;
            align-items: center;
            .title-text {
                color: #666292;
            }
        }
        .title-component-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    .basic-info-div {
        display: flex;
        text-align: left;
        border-radius: 8px;
        flex-direction: column;
        background: #fff;
        width: 75%;
        margin-top: 30px;
        padding: 20px;
        .basic-info-name {
            display: flex;
            flex-direction: row;
            .coupon-is-available {
                display: flex;
                text-align: left;
                flex-direction: column;
                margin-top: 20px;
            }
            .coupon-display-restaurant {
                display: flex;
                text-align: left;
                flex-direction: column;
                margin: 20px;
            }
            .coupon-kind {
                display: flex;
                margin-left: 4%;
                width: 40%;
                flex-direction: row;
                margin-top: 20px;
                .coupon-add {
                    display: flex;
                    border-radius: 8px;
                    background: #f4f7fb;
                    width: 65px;
                    height: 55px;
                    justify-content: center;
                    align-items: center;
                    margin-left: 3%;
                }
            }
        }
        .coupon-restaurant-select-div {
            display: flex;
            border: none;
            flex-direction: column;
            text-align: left;
            background: #f4f7fb;
            border-radius: 8px;
            padding: 20px;
            margin-top: 30px;
            .coupon-restaurant-select {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 10px;
                width: 81%;
                height: 160px;
                background: #f4f7fb;
            }
            .coupon-restaurant {
                display: flex;
                height: 32px;
                padding-left: 5px;
                padding-right: 5px;
                background: #4d5bd8;
                flex-direction: row;
                margin-left: 10px;
                border-radius: 88px;
                align-items: center;
                overflow-y: auto;
            }
        }
    }
    .receive-info {
        display: flex;
        text-align: left;
        border-radius: 8px;
        flex-direction: column;
        background: #fff;
        width: 75%;
        margin-top: 30px;
        padding: 20px;
        .coupon-date-div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
            .coupon-date-btn {
                border-radius: 8px;
                margin-left: 20px;
                background: #4d5bd8;
                color: #fff;
            }
            .not-coupon-date-btn {
                border-radius: 8px;
                margin-left: 20px;
                background: rgb(209, 204, 204);
                color: #fff;
            }
        }
        .coupon-max-kind {
            display: flex;
            margin-top: 40px;
            flex-direction: row;
            width: 100%;
            align-items: center;
            .coupon-total-amount {
                border: none;
                outline: none;
                width: 100px;
                height: 40px;
                margin-left: 15px;
                margin-right: 15px;
                background: #f4f7fb;
                color: #666292;
                padding-left: 10px;
                font-size: 18px;
            }
        }
    }

    .receive-rule {
        display: flex;
        text-align: left;
        border-radius: 8px;
        flex-direction: column;
        background: #fff;
        width: 75%;
        margin-top: 30px;
        padding: 20px;
        margin-bottom: 30px;
        .coupon-max-kind {
            display: flex;
            margin-top: 40px;
            flex-direction: row;
            width: 100%;
            align-items: center;
        }
        .coupon-customer-rule {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .used_info {
        display: flex;
        text-align: left;
        border-radius: 8px;
        flex-direction: column;
        background: #fff;
        width: 75%;
        margin-top: 30px;
        padding: 20px;
        margin-bottom: 30px;
        .basic-info-name {
            display: flex;
            flex-direction: row;
            .coupon-is-available {
                display: flex;
                text-align: left;
                flex-direction: column;
                margin-top: 20px;
            }
            .coupon-display-restaurant {
                display: flex;
                text-align: left;
                flex-direction: column;
                margin: 20px;
            }
            .coupon-kind {
                display: flex;
                margin-left: 4%;
                width: 40%;
                flex-direction: row;
                margin-top: 20px;
                .coupon-add {
                    display: flex;
                    border-radius: 8px;
                    background: #f4f7fb;
                    width: 55px;
                    height: 45px;
                    justify-content: center;
                    align-items: center;
                    margin-left: 3%;
                }
            }
        }
    }

    .coupon-customer-rule {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-top: 40px;
        .coupon-rule-select {
            display: flex;
            flex-direction: row;
            text-align: left;
            align-items: center;
        }
        .coupon-rule-all {
            display: flex;
            margin-top: 15px;
            border: none;
            background: #f4f7fb;
            border-radius: 8px;
            width: 40%;
            height: 180px;
            flex-direction: column;
            .coupon-rule {
                display: flex;
                text-align: left;
                flex-direction: row;
                padding: 20px;
                align-items: center;
                padding-bottom: 0px;
            }
        }
    }

}

.customer-detail {
    display: flex;
    padding: 20px 40px;
    text-align: left;
    flex-direction: column;
    min-height: 800px;
    .customer-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        .customer-title-text {
            font-size: 25px;
            font-weight: bold;
        }
        .customer-back-or-close {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 30px;
        }
    }
    .customer-text {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .customer-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
        .coupon-filter-selected-btn {
            height: 35px !important;
            width: 80px !important;
            background: #ff8058 !important;
            color: #fff !important;
            border-radius: 88px !important;
            margin-left: 15px !important;
            font-size: 13px !important;
        }
        .coupon-filter-btn {
            height: 35px !important;
            width: 80px !important;
            background: #fff !important;
            color: #9aadc8 !important;
            border-radius: 88px !important;
            margin-left: 15px !important;
            border: 1px solid #9aadc8 !important;
            font-size: 13px !important;
        }
    }
}
.customer-single-detail {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 20px 40px;
    .customer-single-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        .customer-single-title-text {
            font-size: 25px;
            font-weight: bold;
        }
        .customer-single-back-or-close {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}

.send-customer {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 20px 40px;
    min-height: 800px;
    .send-customer-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .send-customer-title-text {
            font-size: 24px;
            font-weight: bold;
        }
        .customer-back-or-close {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}

.dialog-add-restaurant-div {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 500px;
    .dialog-add-restaurant-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .dialog-add-restaurant-body {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        .dialog-restaurant-tag-div {
            display: flex;
            height: 32px;
            padding-left: 10px;
            padding-right: 10px;
            flex-direction: row;
            margin-left: 10px;
            margin-top: 10px;
            border-radius: 88px;
            align-items: center;
            overflow-y: auto;
            cursor: pointer;
        }
    }
}
.dialog-prompt-div {
    display: flex;
    width: 400px;
    height: 220px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dialog-btn-div {
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        .dialog-btn {
            margin-top: 20px !important;
            margin-left: 20px !important;
            height: 40px !important;
            width: 100px !important;
            background: #4d5bd8 !important;
            color: #fff !important;
            margin-bottom: 20px !important;
            border-radius: 88px !important;
        }
    }
}

.card-coupon {
    background: #f1f4f8;
    margin: 15px;
    cursor: pointer;
    .text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 500;
        height: 90px;
        text-align: center;
        margin: 10px;
        margin-top: 20px;
        word-wrap: break-word;
        overflow: hidden;
        line-height: 22px;
    }
    .coupon-car-tail-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        width: 180px;
        overflow: hidden;
    }
}

.card-coupon:hover .card-tail-1 {
    background-color: #d98a1a !important;
}
.card-coupon:hover .card-tail-2 {
    background-color: #cc6820 !important;
}

.card-coupon:hover .card-body-1 {
    background-color: #1ab385 !important;
}

.card-coupon:hover .card-body-2 {
    background-color: #168c77 !important;
}
.coupon-car-tail {
    border: none;
    overflow: none;
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coupon-management .loading {
    min-height: 100vh;
}

.coupon-screen {
    width: 200px;
    height: 200px;
}

.kind-com {
    width: 500px;
    height: 200px;
    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 25px;
    }
    .all-kind {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 0 20px;
        .king-btn {
            margin: 0 8px 10px 8px;
            border: 1px solid #e7ecf4;
            border-radius: 20px;
            padding: 4px 15px;
            display: flex;
            .btn {
                color: #4d5bd8;
                cursor: pointer;
                padding-left: 5px;
            }
        }
        .btn {
            color: #4d5bd8;
            cursor: pointer;
        }
        .input-com {
            display: flex;
            flex-direction: row;
            margin-left: 10px;
            border-radius: 88px;
            min-width: 120px;
            border: 1px solid #e7ecf4;
            padding: 0 8px;
            height: 34px;
            align-items: center;
            .input {
                border: none;
                outline: none;
                padding-left: 10px;
                padding-right: 5px;
                border-radius: 88px;
                min-width: 90px;
            }
            .btn {
                color: #4d5bd8;
                cursor: pointer;
            }
        }
    }
}
.separate {
    width: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    margin-top: 10px;
}

.effective-com {
    display: flex;
    margin-top: 20px;
    background-color: #f4f7fb;
    padding: 15px 20px;
    border-radius: 12px;
    flex-direction: column;
    .effective-menu {
        display: flex;
        flex-direction: column;
        padding: 15px 20px;
        background: #fff;
        min-height: 100px;
        margin-top: 20px;
        .effective-menu-top-bar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px;
        }
        .list-data {
            display: flex;
            flex-wrap: wrap;
            .data {
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background-color: #f1f4f8;
                border-radius: 72px;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 5px;
                margin-left: 5px;
            }
            .dish_chip {
                margin-top: 8px;
                margin-left: 8px;
            }
        }
    }
}

// .loading-screen {
//     width: 750px;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     overflow: hidden;
//     position: fixed;
//     z-index: 2;
//     background: rgba(241, 244, 248, .5);
// }

// .dialog-width {
//     width: 800px !important;
//     max-width: 800px;
//     display: flex;
//     flex-direction: column;
//     overflow: hidden;
// }

// .hidden {
//     display: none !important;
// }
.confirm-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .icon {
        color: #FF8058;
        padding: 10px;
        font-size: 36px;
        cursor: pointer;
    }
    .input {
        border: none;
        outline: none;
        width: 60px;
        text-align: center;
        font-size: 24px;
        margin-left: 10px;
    }
    .btn {
        background-color: #4d5bd8;
        color: #FFF;
        margin-left: 20px;
    }
    .btn:hover {
        color: #FFF;
        background-color: rgba(77, 91, 216, 0.8);
    }
}