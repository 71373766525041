.expandContainer {
    .title {
        padding: 20px 30px;
        font-size: 32px;
    }
    .expand-features {
        margin: 0 30px 20px;
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .cardTitle {
                font-size: 24px;
                font-weight: bold;
                margin-left: 20px;
            }
            button {
                margin-right: -8px;
            }
        }
        .deliverect {
            height: 100%;
            margin-top: 20px;
            > div{
                margin-bottom: 16px;
            }
            .url {
                display: flex;
                align-items: center;
            }
        }
    }
}

// .expand-features-dialog {
//     .content {
//         height: 80px;
//     }
// }

// .expandDetail {
//     .top {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         .title {
//             font-size: 20px;
//             font-weight: bold;
//         }
//     }
//     .thirdDataCard {
//         display: flex;
//         align-items: center;
//     }
// }
