// 桌台区域
.table_container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    .table_map {
        background: #f1f4f8;
        border: 1px solid rgba(203, 212, 238, 1);
    }
    .board_helper {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .top_bar {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 56px;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            border-bottom: 1.2px solid #e7ecf2;
            background: #fff;
            cursor: pointer;
            z-index: 999;
        }
    }
    .bottom_container {
        display: flex;
        // flex: 1;
        height: calc(100% - 56px);
        .left_bar {
            width: 180px;
            position: absolute;
            right: 8px;
            top: 68px;
            border: 1px solid #cbd4ee;
            z-index: 999;
            overflow: auto;
            border-radius: 5px;
            backdrop-filter: blur(5px);
            background: #ffffff4d;
            box-shadow: 0px 3px 5px -1px #00000013, 0px 6px 10px 0px #00000016;
            .section_title_area {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 50px;
                .edit_btn {
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px;
                }
            }
            .section_content {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 20px 0;
                .parameter {
                    display: flex;
                    justify-content: center;
                    padding: 10px 10px 0 10px;
                    flex-direction: row;
                    .attributes {
                        width: 80px;
                        text-align: left;
                    }
                }
            }
            .line_separate {
                height: 0.8px;
                background: rgb(170, 185, 221);
                border-bottom-width: 0.8px;
                border-bottom-color: rgb(170, 185, 221);
            }
            .left_icon_btn {
                display: flex;
                // justify-content: center;
                align-items: center;
                min-width: 150px;
                background: #f5fafd;
                height: 42px;
                min-height: 42px;
            }
            .separate {
                border-top-width: 0.000001px;
                border-color: #ebeff6;
                border-style: solid;
                border: 0.5px solid #ebeff6;
            }
            .template_title {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px;
                background: #f5fafd;
                height: 42px;
                min-height: 42px;
                cursor: pointer;
                .title_text {
                    font-size: 13px;
                    width: 125px;
                    text-align: left;
                    align-items: center;
                    padding-top: 2px;
                    font-weight: 500;
                }
                .new_table {
                    font-size: 13px;
                    width: 150px;
                    text-align: left;
                    align-items: center;
                    padding-top: 2px;
                    font-weight: 500;
                }
            }
            .new_table_template {
                // display: block;
                text-align: left;
                flex-direction: column;
                padding: 16px;
                background: #fff;
                height: 400px;
                overflow-y: auto;
                .new_table_title {
                    font-size: 13px;
                    text-align: left;
                    font-weight: 500;
                    margin-top: 24px;
                }
                .new_table_btn {
                    display: flex;
                    flex-direction: row;
                    margin-top: 16px;
                    justify-content: space-between;
                    margin-bottom: 16px;
                }
                .new_template {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 16px;
                    // background: rgba(0, 242, 43, 0.5);
                    background: rgba(255, 255, 255, 0.5);
                    width: 168px;
                    height: 168px;
                }
            }
            .rect {
                width: 50px;
                height: 50px;
                background: rgb(116, 200, 227);
                cursor: grab;
                margin: 20px auto;
            }
        }
    }
    .openCheckBtn {
        position: absolute;
        left: 20px;
        bottom: 20px;
        display: flex;
        align-items: center;
        border: 1px solid #cbd4ee;
        justify-content: center;
        background: #fff;
        .latlng {
            width: 106px;
            color: #cbd4ee;
        }
        .openIcon {
            cursor: pointer;
            padding: 10px;
            display: flex;
            color: #b3b6be;
        }
        .openIcon.disabled {
            cursor: auto;
            pointer-events: none;
        }
        .zoomIcon:hover {
            color: #0a44da;
        }
        .stageScale {
            width: 40px;
            color: #cbd4ee;
        }
    }
}

.table_text,
.table_text:focus {
    position: absolute;
    border: none;
    color: #fff;
    resize: none;
    text-align: center;
    font-size: 12px;
}
.table_text:focus {
    outline: none;
}
