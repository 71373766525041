.common-dialog {
    .common-dialog-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .common-dialog-button {
        padding: 16px 24px;
    }

    .MuiDialogTitle-root {
        padding-bottom: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0;
    }
}