.authorization-component {
    width: 100%;
    height: 100%;
    text-align: left;
    background: #F1F4F8;
    overflow: auto;
    position: relative;
    .top-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
    }

    .details-mode {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        padding: 10px;
        .time-sort-mode {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 20px;
            .sort-mode-point {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-top: 20px;
                .line {
                    width: 100%;
                    height: 1px;
                    border-bottom-color: rgba(255,255,255,0);
                    background: #BFCAE8;
                }
                .sort-mode {
                    display: flex;
                    flex-wrap: wrap;
                    overflow: auto;
                    margin-top: 20px;
                    padding: 10px;
                }
            }
        }
        .summary-mode:hover {
            transform: scale(1.01) translate(0%, 5px);
        }
        .summary-mode {
            display: flex;
            flex-direction: column;
            background: #4D5BDB;
            border-radius: 10px;
            height: 198px;
            width: 186px;
            margin: 10px;
            cursor: pointer;
            .info-bar {
                display: flex;
                width: 186px;
                height: 128px;
                background: #4D5BD8;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                flex-direction: column;
                .label-circle {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .circle {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #B1c3FF;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                        margin-left: 5px;
                    }
                    .circle_1 {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #6A86F1;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                        margin-left: 5px;
                    }
                }
                .circle-div {
                    display: flex;
                    justify-content: flex-end;
                    .circle {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #B1c3FF;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                    }
                    .circle_1 {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #6A86F1;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                    }
                }
                .type-label {
                    display: flex;
                    background: #6A86F1;
                    border-radius: 88px;
                    width: 108px;
                    height: 24px;
                    // justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    color: #FFF;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 12px;
                }
                .name {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                    color: #fff;
                    font-Weight: bold;
                    padding: 20px;
                    width: 148px;
                    height: 47px;
                    word-wrap: break-word;
                    overflow: hidden;
                    text-align: center;
                }
            }
            .tail-bar {
                display: flex;
                width: 186px;
                height: 70px;
                border-radius: 12px;
                background: #2E22A5;
                .rule {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    padding: 5px;
                    .point-date {
                        display: flex;
                        flex-direction: row;
                        width: 186px;
                        justify-content: flex-start;
                        align-items: center;
                        .date {
                            color: #A2B6FF;
                            font-size: 12px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        .summary-mode-shadow:hover {
            transform: scale(1.01) translate(0%, 5px);
        }
        .summary-mode-shadow {
            display: flex;
            flex-direction: column;
            background: #4D5BDB;
            border-radius: 10px;
            height: 198px;
            width: 186px;
            margin: 10px;
            cursor: pointer;
            box-shadow: 7px 7px 1px 1px #8099F1;
            .info-bar {
                display: flex;
                width: 186px;
                height: 128px;
                background: #4D5BD8;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                flex-direction: column;
                .label-circle {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .circle {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #B1c3FF;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                        margin-left: 5px;
                    }
                    .circle_1 {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #6A86F1;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                        margin-left: 5px;
                    }
                }
                .circle-div {
                    display: flex;
                    justify-content: flex-end;
                    .circle {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #B1c3FF;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                    }
                    .circle_1 {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #6A86F1;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                    }
                }
                .type-label {
                    display: flex;
                    background: #6A86F1;
                    border-radius: 88px;
                    width: 108px;
                    height: 24px;
                    // justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    color: #FFF;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .name {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                    color: #fff;
                    font-Weight: bold;
                    padding: 20px;
                    width: 148px;
                    height: 47px;
                    word-wrap: break-word;
                    overflow: hidden;
                    text-align: center;
                }
            }
            .tail-bar {
                display: flex;
                width: 186px;
                height: 70px;
                border-radius: 12px;
                background: #2E22A5;
                .rule {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    padding: 5px;
                    .point-date {
                        display: flex;
                        flex-direction: row;
                        width: 186px;
                        justify-content: flex-start;
                        align-items: center;
                        .date {
                            color: #A2B6FF;
                            font-size: 12px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        .card-point:hover {
            transform: scale(1.01) translate(0%, 5px);
        }
        .card-point {
            display: flex;
            flex-direction: column;
            background: #4D5BDB;
            border-radius: 10px;
            height: 198px;
            width: 186px;
            margin: 10px;
            cursor: pointer;
            .info-bar {
                display: flex;
                width: 186px;
                height: 128px;
                background: #4D5BD8;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                flex-direction: column;
                .label-circle {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .circle {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #B1c3FF;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                        margin-left: 5px;
                    }
                    .circle_1 {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #6A86F1;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                        margin-left: 5px;
                    }
                }
                .circle-div {
                    display: flex;
                    justify-content: flex-end;
                    .circle {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #B1c3FF;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                    }
                    .circle_1 {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        border-radius: 88px;
                        width: 24px;
                        height: 24px;
                        background: #6A86F1;
                        margin-right: 5px;
                        margin-top: 5px;
                        cursor: pointer;
                    }
                }
                .type-label {
                    display: flex;
                    background: #6A86F1;
                    border-radius: 88px;
                    width: 108px;
                    height: 24px;
                    // justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    color: #FFF;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .name {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                    color: #fff;
                    font-Weight: bold;
                    padding: 20px;
                    width: 148px;
                    height: 47px;
                    word-wrap: break-word;
                    overflow: hidden;
                    text-align: center;
                }
            }
            .tail-bar {
                display: flex;
                width: 186px;
                height: 70px;
                border-radius: 12px;
                background: #2E22A5;
                .rule {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    padding: 5px;
                    .point-date {
                        display: flex;
                        flex-direction: row;
                        width: 186px;
                        justify-content: flex-start;
                        align-items: center;
                        .date {
                            color: #A2B6FF;
                            font-size: 12px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}
.top-bar-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.top-bar-mode {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 24px;
    height: 40px;
    margin-right: 20px;
    min-width: 200px;
    .line {
        width: 1px;
        height: 40px;
        border-bottom-color: #fff;
        background: #BFCAE8;
    }
    .mode-right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background: #FFF;
        border: 1px solid #BFCAE8;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        min-width: 90px;
        .icom-text {
            color: #8FA4C2;
        }
        cursor: pointer;
    }
    .mode-left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background: #FFF;
        border: 1px solid #BFCAE8;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        min-width: 90px;
        .icom-text {
            color: #8FA4C2;
        }
        cursor: pointer;
    }
    .selected-mode-left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid #FF8058;
        // border-radius: 24px;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        padding: 10px;
        background: #FF8058;
        min-width: 90px;
        .icom-text {
            color: #FFF;
        }
        cursor: pointer;
    }
    .selected-mode-right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid #FF8058;
        // border-radius: 24px;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        padding: 10px;
        background: #FF8058;
        min-width: 90px;
        .icom-text {
            color: #FFF;
        }
        cursor: pointer;
    }
}
.add-new-print {
    text-align: left;
    background: #F1F4F8;
    width: 100%;
    height: 100%;
    .point-page-top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFF;
        .icon-btn {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
    }
    .card-component {
        padding-bottom: 20px;
        background-color: #F1F4F8;
        .card-component-top-bar {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 20px;
        }
        .point-component {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 10px 10px 40px 10px;
            // padding: 10px;
            .point-card {
                display: flex;
                border-radius: 10px;
                background: #4D5BDB;
                flex-direction: column;
                height: 215px;
                width: 175px;
                align-items: center;
                cursor: pointer;
                padding: 10px;
                .point-img {
                    width: 150px;
                    height: 130px;
                }
                .type-label {
                    display: flex;
                    background: #6A86F1;
                    border-radius: 88px;
                    width: 108px;
                    height: 24px;
                    // justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    color: #FFF;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .name {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                    color: #fff;
                    font-Weight: bold;
                    padding: 20px;
                    width: 128px;
                    height: 47px;
                    word-wrap: break-word;
                    overflow: hidden;
                    text-align: center;
                }
            }
            .point-card:hover {
                transform: scale(1.02) translate(0%, 0px);
                box-shadow: rgba(218, 216, 216, 0.4) 0.5px 0.5px 3px 3px;
            }
        }
        
    }
}

.authorization-detail-component {
    text-align: left;
    background: #FFF;
    min-width: 800px;
    .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon-btn {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .line {
        width: 100%;
        height: 1px;
        border-bottom-color: #fff;
        background: #BFCAE8;
        border-bottom: #fff;
    }
    .point-info {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        .img {
            width: 128px;
            height: 128px;
            padding-left: 20px;
            padding-right: 40px;
            border-radius: 12px;
        }
        .info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .label-info {
                display: flex;
                flex-direction: 'row';
                overflow-x: auto;
                margin-top: 15px;
                .point-label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 26px;
                    background: #4D5BDB;
                    color: #fff;
                    border-radius: 88px;
                    padding-right: 15px;
                    padding-left: 15px;
                    font-size: 13px;
                    margin-right: 15px;
                }
            }
            .point-description {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                text-overflow: ellipsis;
                word-wrap: break-word;
                overflow: hidden;
                margin-top: 20px;
                color: #666292;
                font-size: 14px;
            }
        }
    }
    .point-attrs {
        display: flex;
        flex-direction: column;
        background: #F1F4F8;
        height: 50%;
        min-height: 300px;
        padding-left: 20px;
        padding-bottom: 20px;
        overflow: auto;
        .test {
            .MuiFilledInput-root  {
                background: #FFF;
            }
        }
    }
    .btn {
        display: flex;
        /* margin: 15px; */
        justify-content: flex-end;
        background: #f1f4f8;
        padding: 0 20px 020px 0;
    }
}


.authorization-component::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
}

/*滚动条的轨道*/

.authorization-component::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #F1F4F8;
    border-radius: 5px;
    background: #FFF;
}

/*滚动条里面小滑块*/

.authorization-component::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 5px #fff;
    background: #fff;
}

.pack-component {
    background: #f1f4f8;
    overflow: auto;
    padding: 20px;
    .line {
        width: 100%;
        height: 1px;
        border-bottom-color: #fff;
        background: #fff;
    }
    .pack {
        display: flex;
        flex-direction: column;
        padding: 10px 20px;
    }
    .point-component {
        display: flex;
        flex-direction: row;
        .img-component {
            width: 100px;
            height: 100px;
            margin-top: 10px;
        }
    }
}
