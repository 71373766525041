.theme_manage {
    padding: 30px;
    position: relative;
    min-height: 100vh;
    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .colorBtn {
            height: 30px;
            margin-left: 20px;
            padding: 20px;
            color: #fff;
            border: 2px solid transparent;
        }
        .colorBtn.active {
            border: 2px solid #ff8058;
        }
        .topLeft {
            font-size: 32px;
            font-weight: bold;
        }
    }
    .themePart {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .themeItem {
            border: 1px solid #e7ecf4;
            height: 60px;
            width: 80px;
            color: #fff;
        }
    }
    .special_dish_part {
        position: relative;
    }
    .bottom_part {
        display: flex;
        align-items: center;
        margin-top: 20px;
    }
    .foodImg {
        cursor: pointer;
        width: 189px;
        height: 129px;
        background-size: cover;
        background-position: center;
    }
}

.dish_dialog {
    background: #f4f7fb;
    .title {
        font-size: 15px;
    }
    .dish_dialog_content {
        height: 500px;
        display: flex;
        min-width: 960px;
        .left_content {
            padding: 10px 0 0 0;
            flex: 2 1;
            display: flex;
            flex-direction: column;
            .top {
                margin-left: 24px;
                margin-bottom: 10px;
            }
            .dishList {
                margin-top: 24px;
                flex: 1;
                background: #fff;
                overflow: auto;
                nav {
                    max-width: unset;
                    padding: 0;
                    .MuiTypography-root {
                        color: #666292;
                    }
                    .MuiListItem-gutters {
                        padding-left: 24px;
                    }
                    .MuiListItem-gutters.active {
                        background: #f4f7fb;
                    }
                    ul {
                        max-width: unset;
                        background: #e8eef7;
                    }
                }
                .num {
                    color: #4d5bd8;
                    margin-right: 8px;
                }
            }
        }
        .right_content {
            background: #fff;
            padding: 10px 24px 0;
            flex: 1 1;
            display: flex;
            flex-direction: column;
            .select_chip {
                flex: 1;
                overflow: auto;
                .dish_chip {
                    margin-top: 8px;
                    margin-right: 8px;
                }
            }

            .top {
                .title {
                    color: #666292;
                    font-size: 15px;
                    font-weight: unset;
                }
            }
        }
        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .bottom_button {
        padding: 20px;
        .commonBtn {
            min-width: 120px;
        }
    }
}
