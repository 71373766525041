.pax-pos-page {

    display: flex;
    flex-direction: column;
    padding: 40px 20px;

    .pax-pos-loading {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: fixed;
        width: 100%;
        z-index: 9;
        background: rgba(241, 244, 248, .5);
        top: 0;
        left: 0;
    }

    .title {
        font-size: 32px;
        margin-bottom: 32px;
        font-weight: bold;
        color: black;
    }

    .setting-area {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
    }

}