.set-meal-area {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    .title {
        font-size: 32px;
        margin-bottom: 32px;
        font-weight: bold;
        color: black;
    }
    .top-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .top-bar-left {
            display: flex;
            align-items: center;
        }
    }
}

// 区域详情
.meal-area-detail {
    .meal-area-detail-dialog {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .MuiDialogContent-root {
            flex: 1 1 auto;
        }
    }
    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .typePart {
        display: flex;
        height: 90%;
        justify-content: center;
        align-items: center;
        .typeBtn {
            background: #fff;
            border: 1px solid #4d5bdb;
            color: rgba(77, 91, 219, 1);
            border-radius: 8px;
            height: 192px;
            width: 160px;
            box-shadow: 0px 10px 20px #f0f6ff;
            margin: 20px;
        }
        .typeBtn:hover,
        .typeBtn.active {
            background: #4d5bdb;
            color: #fff;
        }
    }
}

.basic-info-card {
    .MuiCard-root {
        border-radius: 8px;
        border: 1px solid rgb(201, 216, 239);
        padding: 24px;
        box-shadow: 7px 10px 7px #f4f8ff;
    }
}
.pd4 {
    padding: 4px;
}

.meal-area-dialog {
    width: 800px;
    .cardOutLine {
        padding: 30px 30px 60px;
        margin: 0;
    }
    .saveIcon {
        text-align: right;
    }
}
