.root_reservation {
    color: #AAB9DD !important;
    width: 30% !important;
    height: 38px !important;
    border-Radius: 10px !important;
}

.illegal_reservation {
    color: #AAB9DD !important;
    width: 30% !important;
    height: 38px !important;
    border-Radius: 10px !important;
    .MuiOutlinedInput-notchedOutline  {
        border-Color: red !important;
    }
}