.customer-management {
    .filter-select {
        display: inline-block;
        margin-left: 16px;
    }
}

.customer-integral-list {
    margin: 20px;
    .title{
        margin-bottom: 20px;
    }

    .customer-integral-list-table-container {
        height: calc(100vh - 240px);
        overflow: auto;
    }
}