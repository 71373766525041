.basicInfoCard {
    // border: 1px solid rgb(201, 216, 239);
    // box-shadow: 7px 10px 7px #f4f8ff;
    .MuiCard-root {
        border: 1px solid rgb(201, 216, 239);
        box-shadow: 7px 10px 7px #f4f8ff;
        // display: contents;
    }
    .topBox {
        display: flex;
        align-items: center;
        width: 100%;
        .topBoxIcon {
            color: #fa8b73;
        }
        .title {
            flex: 1;
            text-align: left;
            margin-left: 24px;
            font-weight: 700;
            font-size: 18px;
            color: #333333;
        }
    }
    .dateTimePickerInput {
        justify-content: center;
        padding: 0 8px;
        background: #f4f7fb;
        border-radius: 5px;
        height: 55px;
        width: 94%;
        margin-bottom: 13px;
    }
    .dateTimePickerInput label {
        transform: translate(12px, 20px) scale(1);
        color: rgb(170, 185, 221);
    }
    .dateTimePickerInput label.MuiInputLabel-shrink {
        transform: translate(12px, 10px) scale(0.75);
    }
    .dateTimePickerInput label.Mui-error {
        color: #f44336;
    }
    .dateTimePickerInput label + .MuiInput-formControl {
        margin-top: 16px;
    }
    .dateTimePickerInput .MuiInput-underline:before {
        border-bottom: none !important;
    }
    .dateTimePickerInput .MuiInputBase-input {
        color: rgb(170, 185, 221);
    }
}
