.tableBody {
    height: calc(100vh - 200px);
}

.tableBody>div>div {
    width: 100%;
}

.scrollLoad {
    padding: 20px;
    color: #8FA4C2;
}

.scroll-wrapper {
    text-align: center;
    height: calc(100vh - 320px)
}

.tableToolBar {
    display: flex;
    justify-content: flex-end;
}