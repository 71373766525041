.innerTableBody {
}

.innerTableBody>div>div {
    width: 100%;
}

.innerScrollLoad {
    padding: 20px;
    color: #8FA4C2;
}

.innerScroll-wrapper {
    text-align: center;
}

.innterTableComponent .innterBodytr .innterTableHead {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
}
.mb16 {
    margin-bottom: 16px;
}

.innterTableComponent .headth, .innterTableComponent .innterBodytd {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #8FA4C2;
    white-space: nowrap;
    overflow: hidden;
}

.topbodytd {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #8FA4C2;
    white-space: nowrap;
    overflow: hidden;
}

.topheadth {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    color: #8FA4C2;
    white-space: nowrap;
    overflow: hidden;
}

.innterTableComponent .headlabel {
    display: inline-block;
    max-width: 100%;
    text-align: center;
}

.topHeadlabel {
    display: inline-block;
    max-width: 100%;
    text-align: center;
    font-size: 14px;
}

.innterTableComponent .headth button {
    background: #fff;
}

.innterTableComponent .sortIcon {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
}

.innterTableComponent .sortlabel {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.innterTableComponent .sortIcon .topIcon {
    position: absolute;
    top: -4px;
}

.innterTableComponent .sortIcon .bottomIcon {
    position: absolute;
    top: 4px;
}

.innterTableComponent .topIcon.active, .innterTableComponent .bottomIcon.active {
    color: #4d5bd8;
}

.innterTableComponent .innterBodytr, .innterTableComponent .tableHead {
    /* padding: 0 20px; */
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
}

.innterTableComponent .innterBodytr:hover, .innterTableComponent .innterBodytr.active {
    background: #f0f6ff;
}

.innterTableComponent .innterBodytr.disabled {
    background: #f1f4f8;
}

.innterTableComponent .tableHead {
    border: 1px solid transparent;
}

.innterTableComponent .innterBodytr {
    border: 1px solid #e7ecf4;
    background: #F6FBFF;
    margin-bottom: 5px;
}

.topbodytr {
    background: #fff;
    margin-bottom: 5px;
    padding: 0 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
}

.innterTableComponent {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
}