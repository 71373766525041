.imgResultContent {
    height: 400px;
    overflow: auto;
}

.successColor {
    line-height: 35px;
    color: #00e676;
}

.failedColor {
    line-height: 35px;
    color: #f44336;
}