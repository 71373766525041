.menu-management {
    display: flex;
    padding: 40px 24px;
    height: 100%;
    box-sizing: border-box;
    background: #f1f4f8;
    overflow-y: scroll;
}

.menu-management .whiteBtn {
    width: 240px;
    height: 50px;
    color: #666292;
    background: #fff;
    margin-bottom: 8px;
    border-radius: 10px;
}

.menu-management .whiteBtn.active {
    background: #4D5BD8;
    color: #fff;
}

.menu-management .menuLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    width: 240px;
}

.menu-management .addMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.menu-management .menuRight {
    flex: 5;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    width: 0;
}

.menu-management .addData {
    display: flex;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 40px;
}

.menu-management .menuList {
    flex: 1;
    border-radius: 15px;
    width: 100%;
    background: #fff;
    max-height: 100vh;
}

.menu-management .menuListItem {
    height: 100%;
    overflow-Y: auto;
}

.selectedItem {
    background-color: #4D5BD8;
}
.menu-management .MuiTypography-root {
    color: #FFF
}

.coverMask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
  }
.dish-dialog .orangeBtn,
.menu-management .orangeBtn {
    border-radius: 20px;
    margin-left: 15px;
    height: 40px;
    line-height: 40px;
    padding: 0px 10px;
    font-size: 12px;
    color: rgb(180, 196, 238);
    background: transparent;
    border: 1px solid rgb(191, 202, 232);
}
.dish-dialog .orangeBtn.active,
.menu-management .orangeBtn.active {
    color: rgb(255, 255, 255);
    background: rgb(255, 128, 88);
    border: 1px solid rgb(255, 128, 88);
}

.mb20 {
    margin-bottom: 20px;
}

.dish-dialog .imgUpload {
    margin: 0;
    padding: 10px;
    background-color: #f4f7fb;
    border-radius: 10px;
    border: none;
}

.dish-dialog .imgUpload .closeIcon {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
}

.dish-dialog .foodImg {
    position: relative;
    overflow: hidden;
}

.dish-dialog .foodImg:hover {
    transform: scale(1);
}

.dish-dialog .foodImg:hover .closeIcon {
    display: inline-block;
}

.dish-discount {
    flex: 1;
    display: flex;
    justify-Content: center;
    align-Items: center;
    height: 55px;
    border-Radius: 8px;
    border: none;
    color: #fff;
    background-color: #4D5BD8;
    margin-Left: 20px;
    cursor: pointer;
}

.dish-not-discount {
    flex: 1;
    display: flex;
    justify-Content: center;
    align-Items: center;
    height: 55px;
    border-Radius: 8px;
    border: 1px solid #E7ECF4;
    margin-Left: 20px;
    cursor: pointer;
}

.mt20 {
    margin-top: 20px;
}

.importIcon.MuiFab-root {
    width: 32px;
    height: 32px;
    color: white;
    min-height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
}

.import-dialog .contentItem {
    background-color: #f4f7fb;
    border-radius: 10px;
    margin-top: 10px;
    min-height: 20px;
    font-size: 15px;
    padding: 10px 16px;
}

.import-dialog .contentItem .num {
    color: #AAB9DD;
    margin: 0 8px;
}

.import-dialog .cardContent {
    background: rgb(241, 244, 248);
    padding: 20px;
    border-radius: 10px;
}

.order_detail_common .import_menu_name .MuiSelect-select.MuiSelect-select {
    width: 30px;
}

.drawerInput .MuiInputBase-root {
    height: unset !important;
}

.drawerInput .addIcon {
    color: #4d5bd8;
    background: #DBE0F6;
}

.drawerInput .MuiFilledInput-input {
    padding: 36px 12px 10px;
}