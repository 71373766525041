.group_management {
    padding: 40px 20px;
    text-align: left;
}

.grid {
    margin-bottom: 30px;
    margin-top: 20px;
}

.group-grid {
    justify-content: center;
    align-items: center;
    display: flex;
}

.title {
    font-size: 24px;
    font-weight: bold;
}

.group_management .commonBtn {
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    font-size: 12px;
}

.group_management .orangeBtn,
.group_management .orangeBtn:hover {
    color: #fff;
    background-color: #FF8058;
    border: 1px solid #FF8058;
}

.group_management .otherBtn {
    color: #B4C4EE;
    border: 1px solid #bfcae8;
}

.restaurant-detail .activeBtnRes {
    background: #4D5BD8;
    color: #fff;
    border-radius: 88px;
    font-size: 13px;
    margin-left: 10px;
}

.activeBtnRes1 {
    background: #4D5BD8 !important;
    color: #fff !important;
    border-radius: 88px !important;
    font-size: 13px !important;
    margin-left: 10px !important;
}

.otherBtnRes1 {
    background: #fff !important;
    color: #BFCAE8 !important;
    border-radius: 88px !important;
    border: 1px solid #BFCAE8 !important;
    font-size: 13px !important;
    margin-left: 10px !important;
}

.restaurant-detail .otherBtnRes {
    background: #fff;
    color: #BFCAE8;
    border-radius: 88px;
    border: 1px solid #BFCAE8;
    font-size: 13px;
    margin-left: 10px;
}

.group_management .otherBtn:hover {
    background-color: transparent;
}

.group_management .ml16 {
    margin-left: 16px;
}

.group-information {
    text-align: left;
}

.group-mian-info {
    width: 60%;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.group-mian-info-img {
    display: flex;
    flex-Direction: row;
    justify-Content: center;
    align-Items: center;
    margin-Top: 20px;
}

.responsible-person {
    width: 95%;
    height: 400px;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    box-shadow: 7px 10px 7px #F4F8FF;
    /* overflow: auto; */
}

.group-phone {
    width: 40%;
    height: 110px;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.group-phone-new {
    display: contents;
}

.group-phone-new-2 {
    width: 40%;
    height: 110px;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.group-web {
    width: 50%;
    height: 100px;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.group-address {
    width: 50%;
    height: 260px;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.group-fiscal {
    width: 55%;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.group-common-card {
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.group-integral-setting-card {
    width: 40%;
    min-width: 600px;
}

.account-detail {
    padding: 20px 20px;
    text-align: left;
}

.account-detail .account-basic-info {
    width: 60%;
    height: 195px;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.account-detail .frame {
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
}

.account-detail .account-bound {
    width: 95%;
    height: 400px;
    overflow: auto;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.account_dialog_btn {
    margin-Top: 20px;
    height: 40px;
    width: 160px;
    background: #4D5BD8;
    color: #fff;
    margin-Bottom: 20px;
}

.group-opening {
    width: 55%;
    height: 400px;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    overflow-y: auto;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.group-fiscal-info {
    width: 55%;
    height: 580px;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.group-staff-authorization {
    width: 55%;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    box-shadow: 7px 10px 7px #F4F8FF;
    flex-direction: column;
}

.group-staff-authorization .group-staff-authorization-blue-btn {
    margin-top: 16px;
    margin-right: 16px;
    border-radius: 88px;
}

.restaurant-licence {
    width: 60%;
    height: 160px;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
}

.authorization-detail {
    padding: 40px 20px;
    text-align: left;
}

.authorization-component {
    flex-Direction: column;
}

.authorization-package-div {
    display: flex;
    flex-Direction: row;
    background: #fff;
    margin-Top: 20px;
    height: 350px;
}

.authorization-package-add-div {
    display: flex;
    border: 1px solide #BFCAE8;
    background: #F4F7FB;
    width: 144px;
    height: 144px;
    justify-Content: center;
    align-Items: center;
    margin: 10px;
}

.authorization-package-add {
    width: 144px;
    font-Size: 25px;
    font-Weight: 100;
    color: #A8ABAE;
}

.authorization-package {
    padding: 40px 20px;
    text-align: left;
}

.authorization-all-card {
    display: flex;
    flex-Direction: row;
    justify-Content: flex-start;
    width: 100%;
    flex-Wrap: wrap;
}

.point-dateil {
    padding: 40px 20px;
    text-align: left;

}

.authorization-package-div-d {
    background: #4D5BD8;
    border: none;
    border-Radius: 8px;
    height: 312px;
    width: 240px;
    display: flex;
    align-Items: center;
    flex-Direction: column;
    margin: 10px;
}

.authorization-package-title {
    color: #fff;
    font-Size: 15px;
    margin-Top: 30px;
    text-Align: center;
    font-Weight: bold;
    height: 100px;
    width: 70%;
    display: flex;
    justify-Content: center;
    align-Items: center;
}

.authorization-point-div-card {
    background: #4D5BD8;
    width: 144px;
    height: 144px;
    display: flex;
    align-Items: center;
    text-Align: center;
    margin-Left: 10px;
    margin-Top: 20px;
    flex-Direction: column;
}

.authorization-package-detail-div-entry {
    background: #F4F7FB;
    border-Radius: 8px;
    padding: 10px;
    display: flex;
    justify-Content: flex-start;
    margin-Top: 26px;
    height: 200px;
    overflow: auto;
    flex-Direction: column;
}

.authorization-detail-entry-dev {
    background: #F4F7FB;
    border-Radius: 8px;
    padding: 10px;
    display: flex;
    justify-Content: flex-start;
    margin-Top: 26px;
    height: 200px;
    overflow: auto;
    flex-Direction: column;
}

.authorization-detail-date-dev {
    display: flex;
    flex-Direction: row;
    align-Items: center;
    margin-Bottom: 26px;
}

.authorization-detail-btn {
    margin-Top: 20px !important;
    margin-Left: 20px !important;
    height: 30px !important;
    width: 100px !important;
    background: #4D5BD8 !important;
    color: #fff !important;
    margin-Bottom: 20px !important;
    border-Radius: 88px !important;
}

.back-or-close {
    display: flex;
    flex-Direction: row;
    justify-Content: center;
    align-Items: center;
}

.back-or-close-div {
    display: flex;
    justify-Content: space-between;
    align-Items: center;
}

.authorization-detail-entry-width {
    width: 90%;
}

.div-btn {
    display: flex;
    justify-Content: flex-end;
}

.point-dateil-dynamic-rules {
    width: 100%;
    display: flex;
    flex-Direction: column;
}

.point-dateil-rule {
    display: flex;
    flex-Direction: column;
    justify-Content: flex-start;
}

.point-dateil-rule-date {
    display: flex;
    flex-Direction: row;
    align-Items: center;
    margin-Bottom: 26px;
}

.point-dateil-rule-date-div {
    display: flex;
    flex-Direction: column;
    justify-Content: flex-start;
    margin-Bottom: 26px;
}

.authorization-package-card-div {
    display: flex;
    flex-Direction: row;
    overflow-Y: scroll;
}

.package-card {
    background: #4D5BD8;
    border: none;
    border-Radius: 8px;
    height: 312px;
    width: 240px;
    display: flex;
    align-Items: center;
    flex-Direction: column;
    margin: 10px
}

.package-card-title-text {
    color: #fff;
    font-Size: 15px;
    margin-Top: 30px;
    text-Align: center;
    font-Weight: bold;
    height: 100px;
    width: 70%;
    display: flex;
    justify-Content: center;
    align-Items: center;
}

.package-card-border {
    height: 0.8px;
    border-Bottom-Color: #9199E7;
    background: #9199E7;
    width: 33px;
    margin-Top: 20px;
}

.package-img-div {
    margin-Top: 5px;
}

.package-img {
    width: 18px;
    height: 18px;
    margin: 5px;
}

.package-card-rule-date {
    flex-Direction: row;
    justify-Content: center;
    margin-Top: 20px;
    width: 240px;
}

.package-card-btn {
    height: 40px !important;
    width: 160px !important;
    background: #fff !important;
    color: #4D5BD8 !important;
    margin-Top: 20px !important;
    border-Radius: 88px !important;
}

.point-rules-div {
    display: flex;
    background: #F0F3F8;
    margin-Top: 40px;
    flex-Direction: row;
    height: 184px;
}

.piont-rules-div-add {
    display: flex;
    border: 1px solide #BFCAE8;
    background: #F4F7FB;
    width: 144px;
    height: 144px;
    justify-Content: center;
    align-Items: center;
    margin: 10px;
    margin-Top: 20px;
}

.point-card-rules-div {
    display: flex;
    flex-Direction: row;
    overflow-X: auto;
    height: 184px;
}

.point-card {
    background: #7BABFF;
    width: 144px;
    height: 144px;
    display: flex;
    align-Items: center;
    text-Align: center;
    margin-Left: 10px;
    margin-Top: 20px;
    flex-Direction: column;
}

.point-cart-btn {
    height: 30px !important;
    width: 80px !important;
    background: #fff !important;
    color: #4D5BD8 !important;
    border-radius: 88px !important;
}

.point-card-title-text {
    color: #fff;
    width: 124px;
    margin-Top: 20px;
    height: 80px;
    margin-left: 10px;
    margin-right: 10px;
    overflow-wrap: break-word;
}

.bound-dialog-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-weight: 500;
    height: 90px;
    text-align: center;
    margin: 10px;
    margin-top: 20px;
    word-wrap: break-word;
    font-size: 20px;
}

.dialog-component::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
}

/*滚动条的轨道*/

.dialog-component::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #F1F4F8;
    border-radius: 5px;
    background: #FFF;
}

/*滚动条里面小滑块*/

.dialog-component::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 5px #fff;
    background: #fff;
}