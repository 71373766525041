.table_section {
    background: #fff;
    border: 1px solid rgba(228, 239, 255, 1);
    -moz-box-shadow: 0px 10px 20px rgba(142, 156, 176, 0.450980392156863);
    -webkit-box-shadow: 0px 10px 20px rgba(142, 156, 176, 0.450980392156863);
    box-shadow: 0px 10px 20px rgba(142, 156, 176, 0.450980392156863);
    height: calc( 100% - 2px );
    position: relative;
    // 桌台菜单
    .table_menu {
        display: none;
        position: absolute;
        background-color: white;
        -moz-box-shadow: 0px 3px 6px rgba(142, 156, 176, 0.450980392156863);
        -webkit-box-shadow: 0px 3px 6px rgba(142, 156, 176, 0.450980392156863);
        box-shadow: 0px 3px 6px rgba(142, 156, 176, 0.450980392156863);
        border-radius: 3px;
        z-index: 99;
    }

    .table_menu .table_menu_item {
        cursor: pointer;
        padding: 12px 18px;
        font-size: 12px;
    }

    .table_menu .table_menu_item:hover {
        background-color: #cbd4ee;
    }
    .table_menu .table_menu_item.disabled {
        pointer-events: none;
        opacity: .5;
    }
    #table_disable,
    #table_delete,
    #activate_delete,
    #layer_empty {
        color: #ff8058;
    }
    #table_rename,
    #table_paste,
    #layer_paste {
        border-bottom: 1px solid #cbd4ee;
    }
    #table_close {
        display: none;
        position: absolute;
        z-index: 99;
        background: rgba(75, 89, 216, 0.8);
        color: #fff;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
    }
    #table_close:hover {
        background: rgba(75, 89, 216, 1);
    }
    
}
