.dataMonitoring {
    padding: 40px 20px;
    text-align: left;
    .title {
        font-size: 32px;
        font-weight: bold;
    }
    .tool-bar {
        margin-top: 16px;
    }
    .content {
        margin: 20px;
        .content-title {
            font-size: 20px;
        }
        .content-card {
            display: flex;
            margin: 20px;
            flex-wrap: wrap;
            .card {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 153px;
                height: 185px;
                background-color: #fff;
                border: 1px solid #e4e0e0;
                border-radius: 12px;
                margin: 10px;
                padding: 10px;
                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 48px;
                    height: 48px;
                    background: #4d5bd8;
                    color: #fff;
                    border-radius: 50%;
                    margin: 16px 0;
                }
                .bottom {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    color: #8fa4c2;
                }
                .card-title {
                    margin-bottom: 10px;
                    word-break: break-word;
                }
            }
        }
    }
}
