.member-centre {
    display: flex;
    padding: 40px 20px;
    flex-direction: column;
    .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .title {
            font-size: 32px;
            font-weight: bold;
        }
        .icon {
            font-size: 28px;
        }
    }

    .scan-area {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 16px;
        .coupon-write-off {
            font-size: 18px;
            font-weight: bold;
        }
        .scan-input-area {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 0px;
            opacity: 0;
            .scan-input {
                display: flex;
                width: 100%;
                background-color: #fff;
                border-radius: 8px;
                border: none;
                outline: none;
                padding-left: 10px;
            }
            .mb1 {
                border: 1.5px solid #0963e9;
                border-radius: 8px;
            }
            .search-btn {
                background-color: #4d5bd8;
                color: #fff;
                margin-left: 16px;
            }
            .search-btn:hover {
                background-color: #82a4e6;
            }
        }
    }

    .customer-list {
        display: flex;
        flex-direction: column;
        padding: 0px 16px;
        .customer-member-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            .text {
                font-size: 18px;
                font-weight: bold;
            }
        }
        .log-btn {
            background-color: #4d5bd8;
            color: #fff;
            margin-left: 16px;
        }
        .log-btn:hover {
            background-color: #82a4e6;
        }
        .points-record-btn {
            border-radius: 88px;
            background-color: #ff9935;
            color: #fff;
        }
        .reservation_order_chip {
            margin-bottom: -20px;
            min-height: 20px;
        }
    }
    .prompt {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e7ecf2;
        border-radius: 8px;
        min-height: 120px;
        width: 100%;
        background-color: #fff;
        margin-top: 16px;
    }
    .bsc1 {
        border: 1.5px solid #0963e9;
    }
}

.backdrop {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 300px;
    align-items: center;
    padding: 20px;
    .top {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        width: 100%;
    }
    .text-scan {
        margin-top: 65px;
        font-size: 25px;
    }
}

.member-coupon-card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    .top {
        display: flex;
        justify-content: flex-end;
    }
    .title-content {
        display: flex;
        justify-content: space-between;
        min-width: 500px;
        .coupon-customer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .info {
                font-size: 15px;
                font-weight: bold;
            }
        }
        .title-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            .coupon-an {
                font-size: 18px;
                font-weight: bold;
                margin-left: 16px;
            }
            .circle {
                width: 18px;
                height: 18px;
                border-radius: 88px;
            }
            .an-ba {
                background-color: #12bfa0;
            }

            .un-ba {
                background-color: #ff9935;
            }
            .coupon-total {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-left: 15px;
            }
            .coupon-number {
                font-size: 15px;
            }
        }
        .title-right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .coupon-data {
                font-size: 12px;
            }
            .coupon-customer-name {
                font-size: 12px;
            }
        }
    }
    .discount {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left {
            display: flex;
            flex-direction: row;
            .text {
                font-size: 18px;
                font-weight: bold;
                color: #12bfa0;
            }
            .ml20 {
                margin-left: 20px;
            }
            .text-frequency {
                font-size: 18px;
                font-weight: bold;
                color: #12bfa0;
            }
        }
    }
    .rules {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 16px;
        .rule-title {
            font-size: 18px;
            font-weight: bold;
        }
        .rules-table {
            display: flex;
            flex-direction: column;
            width: 100%;
            border: 1px solid #e6e8ec;
            border-radius: 8px;
            margin-top: 8px;
            .table-peripheral {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                align-content: center;
                .table {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 98%;
                    min-height: 40px;
                    margin-left: 1%;
                    .rules-name {
                        font-size: 12px;
                        padding: 5px;
                    }
                }
                .line {
                    height: 0.5px;
                    width: 99.8%;
                    border: 1px solid #e6e8ec;
                    border-bottom-color: #fff;
                    background: #fff;
                }
            }
        }
    }

    .not-use {
        display: flex;
        justify-content: flex-end;
        .btn-use {
            width: 80px;
            background-color: #4d5bd8;
            color: #fff;
            border-radius: 88px;
            margin-left: 10px;
        }
        .btn-use:hover {
            background-color: #9ea7e8;
        }
    }

    .bottom-bar {
        flex-direction: column;
        margin-top: 16px;
        .bottom-bar-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .btn-group {
                display: flex;
                flex-direction: row;
                align-items: center;
                .btn-use {
                    width: 80px;
                    background-color: #4d5bd8;
                    color: #fff;
                    border-radius: 88px;
                    margin-left: 10px;
                }
                .btn-use:hover {
                    background-color: #9ea7e8;
                }
                .btn-un-use {
                    width: 80px;
                    background-color: #bec0c4;
                    color: #fff;
                    border-radius: 88px;
                    margin-left: 10px;
                }
            }
            .remark {
                font-size: 15px;
                font-weight: bold;
                cursor: pointer;
            }
            .dropdown {
                cursor: pointer;
            }
        }
        .remarks-text {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }
}

.update-integral {
    display: flex;
    flex-direction: column;
    padding: 20px;
    .title {
        font-size: 18px;
        font-weight: bold;
    }
    .btn-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .btn {
            background-color: #4d5bd8;
            color: #fff;
            border-radius: 40px;
            min-width: 100px;
            margin: 8px;
        }
        .btn:hover {
            background-color: #82a4e6;
        }
    }
}

.an {
    color: #12bfa0;
}

.un {
    color: #ff9935;
}

.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.table-line-an {
    border: 0.6px solid #12bfa0;
}
.table-line-un {
    border: 0.6px solid #ff9935;
}

.not-data {
    padding: 20px;
}
