.historicalReservation {
    padding: 20px;
    padding-top: 8px;
    .reservation_order_part {
        display: flex;
        padding: 8px;
        .selectSearch {
            border: 1px solid #e7ecf4;
            border-radius: 20px;
            .searchSelsctValue {
                margin: 0;
                .MuiSelect-select.MuiSelect-select {
                    width: auto;
                    border-radius: 0 20px 20px 0;
                    border: none;
                    border-right: 1px solid #e7ecf4;
                }
            }
        }
        .selectinput {
            display: inline-flex;
            .MuiFormControl-root {
                margin-left: 0;
                .MuiSelect-select.MuiSelect-select {
                    width: auto;
                    border-radius: 20px 0 0 20px;
                    border: none;
                    border-right: 1px solid #e7ecf4;
                }
            }
        }
        .datePicker {
            .commonBtn {
                border-radius: 0 20px 20px 0;
                height: 40px;
                line-height: 40px;
                padding: 0 16px;
                font-size: 12px;
                margin-left: 0;
            }
            .whiteBtn,
            .whiteBtn:hover {
                color: #8fa4c2;
                background-color: #fff;
                border: none;
            }
        }
    }

    .ml16 {
        margin-left: 16px;
    }
    .pr120{
        padding-right: 120px;
    }
}
