.relevance-meal {
    padding: 24px;
    margin: 16px 0;
    border-radius: 8px;
    border: 1px solid #c9d8ef;
    width: 600px;

    .relevance-meal-text {
        font-size: 14px;
        color: #8FA4C2;
    }
}

.relevance-text {
    margin-bottom: 20px;
}