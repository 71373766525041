.reservation-component {
    display: flex;
    height: 100%;
    flex-direction: row;
    .screen {
        display: flex;
        overflow: hidden;
        position: absolute;
        width: calc(100% - 360px);
        height: calc(100% - 111px);
        margin-left: 360px;
        margin-top: 111px;
        z-index: 99;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 1);
        color: #8fa4c2;
    }
    .right-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        z-index: 1;
        overflow: hidden;
    }
    .top-bar {
        display: flex;
        background: #fff;
        flex-direction: column;
        height: 114px;
        .top-date {
            display: flex;
            align-items: center;
            height: 56px;
            justify-content: space-between;
            padding-right: 80px;
            .top_date_left {
                display: flex;
                align-items: center;
            }
            .top_date_right {
                margin-right: 20px;
                align-items: center;
                display: flex;
            }
            .date-table {
                display: flex;
                height: 40px;
                border: 1px solid #e7ecf4;
                border-radius: 88px;
                width: 125px;
                justify-content: center;
                align-items: center;
                padding-left: 10px;
                margin-top: 8px;
                margin-bottom: 8px;
                .MuiInput-underline:before {
                    border: none;
                }
                .MuiFormControl-marginNormal {
                    margin-top: 8px;
                    margin-bottom: 8px;
                }
                .MuiInputBase-input {
                    color: #8fa4c2;
                    font-size: 13px;
                    font-weight: 600;
                    width: 80px;
                }
                .MuiSvgIcon-root {
                    color: #8fa4c2;
                }
                .MuiIconButton-root {
                    padding: 0px 10px 0px 0px;
                }
            }
            .icon-arrow {
                margin-left: 20px;
                margin-right: 20px;
                color: #8fa4c2;
                cursor: pointer;
            }
        }
        .dividing-line {
            border-top-width: 0.000001px;
            border-color: #ebeff6;
            border-style: solid;
            border: 0.5px solid #ebeff6;
        }
    }
    .table-konva-component {
        display: flex;
        height: calc(100vh - 115px);
        position: relative;
    }
    .floor-plan-bottom-feature {
        position: absolute;
        bottom: 0;
        z-index: 9;
        right: 0;
        left: 0;
        background: #fff;
        padding: 16px;
        display: flex;
        justify-content:space-between;
    }
    .area-name {
        display: flex;
        position: absolute;
        top: 122px;
        left: 80px;
        width: 200px;
        height: 80px;
        z-index: 999;
        color: #8fa4c2;
    }
    .top-rect {
        display: flex;
        position: absolute;
        top: 112px;
        left: 0px;
        background: #f1f4f8;
        width: 80px;
        height: 80px;
        z-index: 999;
    }
    .konva-component {
        display: flex;
        flex: 3;
        width: 100%;
        height: 100%;
        background: "#F1F4F8";
        flex-direction: column;
        .panpel-component {
            display: flex;
            flex-direction: row;
            background: #fff;
            .table-component {
                background: #f1f4f8;
            }
        }
    }
    .not-data {
        display: flex;
        z-index: 999;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: #8fa4c2;
    }
    .order-card {
        display: flex;
        flex-direction: column;
        min-width: 360px;
        background: #f5fafd;
        width: 360px;
        height: 100vh;
        overflow: hidden;
        position: relative;
        .reservation_order_part_top {
            padding: 8px;
            border-bottom: 1px solid #e0e8f1;
            text-align: left;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-shrink: 0;
            .table-name-text {
                color: #8fa4c2;
                .MuiTypography-body1 {
                    font-weight: 600;
                }
            }
            .distribution-loading {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .reservation_order_part {
            flex-shrink: 0;
            display: -webkit-inline-box;
            // justify-content: space-between;
            // padding: 8px;
            // border-bottom: 1px solid #e0e8f1;
            // height: 90px;
            .selectSearch {
                border: 1px solid #e7ecf4;
                border-radius: 20px;
            }
            .selectinput {
                display: inline-flex;
                .MuiFormControl-root {
                    margin-left: 0;
                    .MuiSelect-select.MuiSelect-select {
                        width: auto;
                        border-radius: 20px 0 0 20px;
                        border: none;
                        border-right: 1px solid #e7ecf4;
                    }
                }
            }
        }
        .reservation_order_btn {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            color: #878e9a;
            position: absolute;
            bottom: 20px;
            z-index: 10;
            background: transparent;
            backdrop-filter: blur(2px);
            border-radius: 10px;
            flex-wrap: wrap;
            .rect-color-box {
                display: block;
                width: 5px;
                height: 20px;
                border-radius: 5px;
            }
            button {
                // flex: 1 0 auto;
                width: 30%;
                margin: 0 4px;
                text-transform: unset;
                padding: 0 4px;
            }
            .MuiButton-label {
                font-size: 10px;
                line-height: normal;
            }
            .unconfirmed_text {
                color: #878e9a;
            }
            .confirmed_text {
                color: #0ec5ff;
            }
            .finished_text {
                color: #6fe277;
            }
            .unconfirmed_rect_color {
                background: linear-gradient(to right bottom, #fff, #e8e8e8);
                border: 1px solid #e8e8e8;
            }
            .confirmed_rect_color {
                background: #0ec5ff;
                border: 1px solid #08ade2;
            }
            .finished_rect_color {
                background: #6fe277;
                border: 1px solid #60cf68;
            }
        }
        .reservation_order_card {
            overflow-y: scroll;
            flex: 1 1 auto;
            padding-bottom: 80px;
            .checkBox {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: inline-block;
                background: #d9dde6;
                visibility: hidden;
                margin-right: 0;
                .checkIcon {
                    color: #4d5bdb;
                    visibility: hidden;
                }
            }
            .iconBtn {
                cursor: pointer;
                margin-left: 8px;
                margin-right: 8px;
            }
            .reservation_order_card_item {
                background: #fff;
                margin: 15px 8px;
                border: 1px solid #e7ecf4;
                border-radius: 0 10px 10px 0;
                display: flex;
                align-items: center;
                position: relative;
                cursor: grab;
                .reservation_order_card_line {
                    height: 80px;
                    width: 4px;
                }
                .reservation_order_card_test {
                    position: absolute;
                    left: -1px;
                    top: -11px;
                    z-index: 9;
                    background: #ff8058;
                    color: #fff;
                    border-radius: 88px;
                    font-size: 12px;
                    padding: 3px 6px;
                }
                .reservation_order_card_Alrm_icon_box {
                    position: absolute;
                    top: -11px;
                    right: -5px;
                    background-color: #4d5bd8;
                    width: 26px;
                    height: 26px;
                    border-radius: 26px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg {
                        color: #fff;
                        width: 77%;
                        height: 77%;
                    }
                }
                .reservation_order_card_content {
                    flex: 1 0 auto;
                    padding: 0 12px;
                    .reservation_order_card_top {
                        display: flex;
                        justify-content: space-between;
                        // margin-bottom: 10px;
                    }
                    .reservation-customer-info-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-right: 40px;
                        .reservation-customer-phone {
                            font-size: 12px;
                            color: #b6b1bfe4;
                        }
                        .reservation-customer-seat {
                            font-size: 12px;
                            color: #b6b1bfe4;
                        }
                    }
                    .reservation_order_card_bottom {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #b6b1bfe4;
                        .reservation_card_bottom_left {
                            font-size: 12px;
                        }
                    }
                    .reservation_card_top_left {
                        font-weight: bold;
                        width: 160px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-align: left;
                        text-overflow: ellipsis;
                    }
                    .reservation_card_top_right {
                        display: flex;
                        color: #4d5bdb;
                        font-weight: bold;
                    }
                    .reservation_card_bottom_right {
                        display: flex;
                        color: #b6b1bfe4;
                    }
                }
            }
            .distable-draggable {
                cursor: pointer;
            }
            // 未确认
            .reservation_order_card_item.unconfirmed {
                .checkBox {
                    visibility: visible;
                }
            }

            // 未分配桌台
            .reservation_order_card_item.unassigned {
                .checkBox {
                    visibility: visible;
                }
            }
            // 已确认
            .reservation_order_card_item.confirmed {
                .reservation_order_card_line {
                    background-color: #0ec5ff;
                }
            }

            // 已完成
            .reservation_order_card_item.finished {
                .reservation_order_card_line {
                    background-color: #6fe277;
                }
            }

            // 点击时样式
            .reservation_order_card_item.clicked {
                color: #fff;
                background: #4d5bdb;
                .reservation_order_card_bottom,
                .iconBtn,
                .reservation_card_bottom_right,
                .reservation_card_top_right {
                    color: #fff;
                }
            }
            // 未确认 已勾选
            .reservation_order_card_item.unassigned.selected,
            .reservation_order_card_item.unconfirmed.selected {
                color: #fff;
                background: #4d5bdb;
                .reservation_order_card_bottom,
                .iconBtn,
                .reservation_card_bottom_right,
                .reservation_card_top_right {
                    color: #fff;
                }
                .checkBox {
                    visibility: visible;
                    .checkIcon {
                        visibility: visible;
                    }
                }
            }
        }
    }
}
.reservation_detail {
    .reservation_detail_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .reservation_detail_icon {
            margin-right: -12px;
            button {
                color: #a9abae;
            }
        }
    }
}

.btn-area::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 12px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 3px;
}

/*滚动条的轨道*/

.btn-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #f1f4f8;
    box-shadow: inset 0 0 5px #f1f4f8;
    border-radius: 5px;
    background: #fff;
}

/*滚动条里面小滑块*/

.btn-area::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 5px #d4ddf2;
    box-shadow: inset 0 0 5px #d4ddf2;
    background: #d4ddf2;
}

.reservation-setting {
    text-align: left;
    .time-setting {
        height: 90%;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 40px;
        width: 50%;
        padding: 20px 20px;
        background: #fff;
        border-radius: 8px;
        min-width: 530px;
        .switch-btn {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 20px;
            margin-left: -10px;
        }
        .no-aividing-line {
            border-bottom-width: 0px;
            border-bottom-color: #fff;
        }
        .aividing-line {
            height: 1px;
            background: #aab9dd;
            border-bottom-width: 1px;
            border-bottom-color: #aab9dd;
        }
        .day-time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 35px;
            .time-array {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                width: 75%;
                overflow: hidden;
            }
        }
        .closed-calendar {
            border: 1px solid #4d5bdb;
            border-radius: 4px;
            width: 60%;
            min-width: 420px;
            .top-bar {
                display: flex;
                padding: 15px;
                justify-content: space-between;
                .left-top {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    .top-text {
                        margin-left: 10px;
                        font-size: 14px;
                        color: #575988;
                    }
                }
            }
            .line {
                height: 0.7px;
                border: 0.7px solid #d9d9da;
                border-bottom-color: #fff;
                background: #d9d9da;
            }
            .calendar {
                .DayPicker-Months {
                    background: #fff;
                }
                .DayPicker-Day {
                    padding: 0em;
                    border-radius: 0px;
                }
                .DayPicker-Caption {
                    color: #4d5bd8;
                }
            }
            .closed-text {
                margin: 20px;
                .date {
                    color: #4b5dbd;
                    margin-left: 8px;
                }
            }
            .closed-event {
                margin-top: 20px;
                .date-event {
                    display: flex;
                    align-items: center;
                    padding-left: 40px;
                    padding-top: 20px;
                    .date {
                        color: #4b5dbd;
                        margin-left: 8px;
                    }
                }
                .checked-date {
                    display: flex;
                    align-items: center;
                    padding-left: 30px;
                    padding-top: 10px;
                }
                .checked-time {
                    display: flex;
                    align-items: center;
                    padding-left: 30px;
                }
                .label-div {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-left: 30px;
                    .label {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 26px;
                        background: #eff2f7;
                        color: #7d8a97;
                        border-radius: 88px;
                        padding-right: 15px;
                        padding-left: 15px;
                        font-size: 13px;
                        margin-right: 15px;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }
                    .select-label {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 26px;
                        background: #4d5bdb;
                        color: #fff;
                        border-radius: 88px;
                        padding-right: 15px;
                        padding-left: 15px;
                        font-size: 13px;
                        margin-right: 15px;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }
                }
            }
            .calendar-btn {
                display: flex;
                padding: 10px;
                align-items: center;
                justify-content: space-evenly;
            }
        }
    }
    .card-item {
        margin: 30px 40px;
        width: 50%;
        padding: 20px;
        background: rgb(255, 255, 255);
        border-radius: 8px;
        text-align: left;
    }
}

// 订单悬浮框
#popover_info {
    position: absolute;
    z-index: 999;
    background: #fff;
    -moz-box-shadow: 0px 10px 20px rgba(80, 84, 91, 0.0941176470588235);
    -webkit-box-shadow: 0px 10px 20px rgba(80, 84, 91, 0.0941176470588235);
    box-shadow: 0px 10px 20px rgba(80, 84, 91, 0.0941176470588235);
    border: 1px solid #e7ecf4;
    border-radius: 10px;
    padding: 8px;
    color: #b6b1bf;
    font-size: 12px;
    width: 100px;
    transform: translate(100%);
    margin-left: -38px;
}

#popover_info .popover_info_item {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#popover_info .popover_info_date {
    word-break: break-word;
}

.active-time-component {
    min-width: 530px;
    .line {
        height: 0.7px;
        border-bottom-color: #d9d9da;
        background: #d9d9da;
    }
    .time-range {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-bottom: 10px;
        .time-part {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}

.closed-calendar-component {
    display: flex;
    justify-content: flex-start;
    .calendar {
        .DayPicker-Months {
            background: #fff;
        }
        .DayPicker-Day {
            padding: 0em;
            border-radius: 0px;
        }
        .DayPicker-Caption {
            color: #4d5bd8;
        }
    }
}

// 任务列表下拉框
.task_popover {
    width: 400px;
    min-height: 80px;
    padding: 30px;
    .taskItem {
        margin-bottom: 10px;
        border: 1px solid #e7ecf4;
        padding: 10px;
        border-radius: 0 10px 16px 0;
        .taskTop {
            display: flex;
            margin-bottom: 10px;
            justify-content: space-between;
        }
        .taskBottom {
            display: flex;
            justify-content: space-between;
        }
        .taskDesc {
            color: #b6b1bfe4;
            font-size: 14px;
        }
        .taskStatus {
            display: flex;
        }
        .taskName {
            flex-basis: 50%;
            font-weight: bold;
        }
        .taskTime {
            color: #4d5bdb;
            font-weight: bold;
        }
    }
    .deleteIcon {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
    }
}

.illegal {
    color: #aab9dd !important;
    // width: 30% !important;
    height: 38px !important;
    border-radius: 10px !important;
    .MuiOutlinedInput-notchedOutline {
        border-color: red !important;
    }
}

.checkoutDateSwitchBox {
    .checkoutDateSwitch {
        width: 80px;
        height: 40px;
        margin: 8px;
        padding: 1px;
        border-radius: 88px;
        z-index: 99;
        .icon {
            background-color: #fff;
            border-radius: 88px;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .MuiSwitch-switchBase {
            top: 1.5px;
            left: 3px;
        }
    }
    .open {
        .MuiButtonBase-root.MuiIconButton-root {
            color: #fff;
            transform: translateX(38px);
        }
    }
}

.checkoutHistorySwitchBox {
    .checkoutHistorySwitch {
        position: fixed;
        top: 0px;
        right: 10px;
        width: 80px;
        height: 40px;
        margin: 8px;
        padding: 1px;
        border-radius: 88px;
        z-index: 99;
        .icon {
            background-color: #fff;
            border-radius: 88px;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .MuiSwitch-switchBase {
            top: 1.5px;
            left: 3px;
        }
    }
    .open {
        .MuiButtonBase-root.MuiIconButton-root {
            color: #fff;
            transform: translateX(38px);
        }
    }
}

.order-card .reservation_order_part .selectSearch .searchSelsctValue .MuiSelect-select.MuiSelect-select {
    width: auto;
    border-radius: 0 20px 20px 0;
    border: none;
}
.order-card .reservation_order_part .selectSearch .searchSelsctValue {
    margin: 0;
}

.basicInfoCard .basicInfoCardContent {
    overflow: visible;
    .selected-flag {
        width: auto;
        margin-left: 0;
    }
}

.FCC {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dateTimePickerInput {
    justify-content: center;
    padding: 0 8px;
    background: #f4f7fb;
    border-radius: 5px;
    height: 55px;
    width: 94%;
    margin-bottom: 13px;
}
.dateTimePickerInput label {
    transform: translate(12px, 20px) scale(1);
    color: rgb(170, 185, 221);
}
.dateTimePickerInput label.MuiInputLabel-shrink {
    transform: translate(12px, 10px) scale(0.75);
}
.dateTimePickerInput label.Mui-error {
    color: #f44336;
}
.dateTimePickerInput label + .MuiInput-formControl {
    margin-top: 16px;
}
.dateTimePickerInput .MuiInput-underline:before {
    border-bottom: none !important;
}
.dateTimePickerInput .MuiInputBase-input {
    color: rgb(170, 185, 221);
}
