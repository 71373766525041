.MuiTreeItem-content-tax {
    width: 180;
}

/* .MuiTypography-root-tax {
    height: 100%;
    /* height: 40px; */

/* width: 200px; */

/* justify-Content: flex-start;
    align-Items: center;
    display: flex; */

/* } */

.MuiSvgIcon-root-tax {
    margin-Left: 320px;
}

.MuiTreeItem-label {
    text-align: left;
    height: 40px;
    line-height: 40px !important;
}

/* 二维码页面样式 */

.setting {
    overflow: hidden;
}

.setting .content {
    display: flex;
    margin: 20px;
}

.commonCard {
    border-radius: 10px;
    padding: 24px;
    margin: 10px;
}

.commonCard1 {
    border-radius: 10px;
    padding: 24px;
    margin-left: 10px;
}

.setting .codeCard {
    width: 300px;
}

.setting .stringIdCard {
    width: 300px;
}

.setting .urlCard {
    width: 80%;
    position: relative;
}

.setting .commonCard1 {
    width: 100%;
}

.setting .badgeNew {
    position: absolute;
    right: -10px;
    top: -10px;
}

.codeCard .top {
    margin-bottom: 20px;
}

.setting .commonCard .bottom {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stringIdCard .string_id {
    font-size: 50px;
    font-weight: bold;
}

.urlCard .url {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mb16 {
    margin-bottom: 16px;
}

.ml16 {
    margin-left: 16px;
}

.tax-detail .footerBtn {
    text-align: center;
}

.update-tax .order_detail_common {
    padding: 20px;
}

.update-tax .title,
.update-tax .desc {
    margin-left: 16px;
}

.update-tax .title {
    font-size: 24px;
    margin-bottom: 16px;
}

.update-tax .desc {
    font-size: 16px;
    margin-bottom: 32px;
}

.update-tax .footerBtn {
    text-align: center;
}



.pay_setting_box {
    margin: 40px 0 40px 30px;
    width: 600px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
}

.pay_setting_paypal {
    display: flex;
    flex-Direction: column;
    justify-Content: flex-start;
    align-Items: flex-start;
}

.pay_setting_paypal_text {
    font-size: 12px;
    color: rgb(139, 191, 251);
    margin-left: 15px;
    margin-bottom: 10px;
}

.pay_setting_paypal_box {
    display: flex;
    margin-top: 30px;
}

.print-setting {
    padding: 24px;
    margin: 10px;
}

.print-setting .commonCard {
    border-radius: 10px;
}

.interval-time {
    height: 120px;
    margin-Top: 30px;
    margin-Left: 40px;
    width: 50%;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    margin-Bottom: 30px;
}

.tableBox {
    margin-top: 20px;
    background: rgb(241, 244, 248);
    border-radius: 10px;
    padding: 16px;
}

.inline-url-div {
    display: flex;
    flex-direction: column;
    background: #F4F7FB;
    text-align: left;
    border: 'none';
    border-radius: 8px;
    padding: 15px;
}

.text-url {
    color: #666292;
    margin-left: 10px;
}

.text-url-title {
    color: #666292;
}

.code-btn {
    justify-content: center !important;
    align-items: center !important;
    border: none !important;
    border-radius: 8px !important;
    background: #4D5BD8 !important;
    margin-left: 15px !important;
    height: 40px !important;
    font-size: 10px !important;
    color: #fff !important;
    padding: 2px 5px !important;
    overflow: hidden !important;
}

.isv_info {
    text-align: left;
}

.isv_info_box {
    background: #F4F7FB;
    border-radius: 10px;
    margin: 10px 0;
    padding: 10px;
}

.isv_info_label {
    color: rgb(170, 185, 221);
    font-size: 12px;
}

.isv_info_text {
    color: #AAB9DD;
    font-size: 16px;
    display: flex;
}

.isvBasicInfoCard {
    height: 80px;
}