.dish-dialog {
    .dish-content {
        width: 810px;
    }
    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: -12px;
        .title {
            font-size: 18px;
            font-weight: bold;
        }
    }
    .typePart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 0 20px;
        .typeBtn {
            background: #fff;
            border: 1px solid #e7ecf4;
            box-shadow: 0px 10px 20px #f0f6ff;
            margin: 5px;
            width: 283px;
            height: 68px;
            border-radius: 10px;
            justify-content: flex-start;
            .leftIcon {
                width: 48px;
                height: 48px;
                background-color: #4d5bd8;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                margin: 0 10px;
            }
        }
        .typeBtn:hover,
        .typeBtn.active {
            background: #4d5bdb;
            color: #fff;
        }
    }
    .footerPart {
        .footerBtn {
            margin-left: 8px;
            border-radius: 20px;
        }
    }
    .dishFormItem {
        display: flex;
        margin-bottom: 20px;
        .leftPart {
            display: flex;
            flex-direction: column;
            flex: 1 1 0%;
        }
        .rightPart {
            display: flex;
            flex-direction: column;
            flex: 4 1 0%;
            margin-left: 25px;
        }
    }
    .addImageIcon {
        color: #dce0f5;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .MuiDialogContent-root {
        overflow: unset;
    }
    .reportDetails {
        .text {
            color: #8FA4C2;
        }
    }
}

.takeaway-and-selfTake {
    flex: 1;
    margin-left: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    .iconButton {
        background: #dce0f5;
        color: #fff;
        margin-left: 20px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .iconButton.active {
        background: #ff8058;
    }
}

.mealArea {
    background: #f4f7fb;
    border-radius: 10px;
    padding-bottom: 1px;
    .label {
        color: #aab9dd;
        font-size: 16px;
        transform: translate(12px, 10px) scale(0.75);
        display: inline-block;
    }

    .mealAreaContent {
        padding: 26px 12px 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .mealAreaButtonBox {
        margin: 0 40px;
        .mealAreaButton {
            background: #fff;
            width: 76px;
            height: 74px;
            border-radius: 10px;
            display: block;
            border: 1px solid #e6edf7;
            color: #4d5bd8;
            margin-bottom: 10px;
        }
    }
    .mealAreaItem {
        background: #fff;
        border: 1px solid #e7ecf4;
        border-radius: 10px;
        width: 144px;
        height: 199px;
        margin: 10px 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        .mealAreName {
            color: #666292;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
        }
        .mealAreaNum {
            color: #666292;
            margin: 10px 0;
        }
        .mealAreaLabel {
            background: #4d5bd8;
            color: #fff;
            border-radius: 10px;
            width: 128px;
            height: 32px;
            font-size: 12px;
            line-height: 32px;
            text-align: center;
            margin: 5px 0;
        }
        .deleteIcon {
            position: absolute;
            right: -8px;
            top: -8px;
            background: #4d5bd8;
            color: #ffff;
            display: none;
            width: 20px;
            height: 20px;
            svg {
                width: 16px;
                height: 16px;
            }
        }
        &:hover {
            .deleteIcon {
                display: flex;
            }
        }
    }
    .mealAreaItem.active {
        background: #4d5bd8;
        .mealAreaNum,
        .mealAreName {
            color: #fff;
        }
        .mealAreaLabel {
            background: #fff;
            color: #4d5bd8;
        }
        &:hover {
            .deleteIcon {
                display: none;
            }
        }
    }
    .saveIcon {
        text-align: right;
    }
}

.cardOutLine {
    background: #fff;
    margin: 12px 12px 20px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #e7ecf4;
    position: relative;
}

.menu-management .check-box {
    .MuiSvgIcon-root {
        background-color: #f4f7f8;
        height: 0.8em;
        width: 0.8em;
        border: none;
        color:  #f4f7f8;
    }
}
.menu-management .check {
    .MuiSvgIcon-root {
        background-color: #858FE4;
        height: 0.8em;
        width: 0.8em;
        border: none;
        color:  #858FE4;
    }
}