.reservation-table {
    &-list {
        background-color: #fff;
        text-align: left;
        overflow: auto;
        padding: 20px;
        width: 100%;
        padding-bottom: 60px;
    }

    &-box {
        width: 100%;
        height: 100%;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-item.MuiButtonBase-root {
        background-color: rgb(230, 232, 243);
        border: 6px solid rgb(230, 232, 243);
        padding: 10px 6px;
        color: rgb(87, 113, 152);
        width: 105px;
        height: 105px;
        border-radius: 16px;
        margin: 5px;
        display: inline-block;
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
        overflow: hidden;
        position: relative;


        &.active {
            background: rgb(88, 152, 225);
            border: 6px solid rgb(88, 152, 225);
            color: #fff
        }

        &.disabled {
            cursor: auto;
            // pointer-events: none;
            background: #e0e0e0;
            border: 6px solid #e0e0e0;
        }

        &:hover,
        &.selected {
            border: 6px solid #55D6FF;
        }

        &.new::after {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #ff8058;
            position: absolute;
            top: 6px;
            right: 6px;
        }

    }

    &-bottom {
        display: flex;
        position: absolute;
        bottom: 10px;
        font-size: 18px;
        font-weight: normal;
        align-items: center;
    }

    &-icon {
        color: #fff;
        width: 30px;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
    }
}