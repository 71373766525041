.print-detail {
    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .commonBtn {
        border-radius: 20px;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        font-size: 12px;
    }

    .otherBtn {
        color: #b4c4ee;
        border: 1px solid #bfcae8;
    }
    .pupleBtn,
    .pupleBtn:hover {
        color: #fff;
        background-color: rgba(77, 91, 216, 0.8);
    }
    .print-detail-footer .ml8{
        margin-left: 8px;
    }
}

.hidden {
    display: none !important;
}

.slect-input {
    .select-option:first-child{
        margin-top: 20px;
    }
    .select-option {
        width: 60%;
        border-radius: 10px;
        margin: 10px;
        padding: 12px 15px;
        color: rgba(77, 91, 216, 0.8);
        background-color: #fff;
        border: 1px solid rgba(77, 91, 216, 0.8);
        text-transform: unset;
    }
    .select-option.active {
        color: #fff;
        background-color: rgba(77, 91, 216, 1);
    }

    .MuiButton-root.Mui-disabled{
        border: 1px solid rgba(0, 0, 0, 0.26);  
    }
    .input-option {
        margin: 10px;
        width: 60%;
    }
}
.dialog-title {
    .MuiStepper-alternativeLabel {
        width: 60%;
    }
}
