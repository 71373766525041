.dialog_div_phone {
    display: inlink-flex;
    width: 282px;
    height: 45px;
    border-Radius: 10px;
    color: #fff;
    background: #F4F6FA;
    border: none;
    flex-Direction: column;
}

.login {
    display: flex;
    flex: 1;
    background: #F4F6FA;
    flex-Direction: column;
    height: 100vh;

    .login_link {
        margin-top: 24px;
    }
}

.login_div_mian {
    display: flex;
    flex-Direction: column;
    align-Items: center;
    justify-Content: center;
    width: 100%;
    min-height: 80vh;
}

.login_dialog_formControl {
    flex: 1;
    width: 120px;
    height: 45px;
    border: none;
    outline: none;
    margin-Left: 20px;
    background: #F4F6FA;
}

.login_verification_button {
    margin-top: 20px;
    justify-Content: flex-end;
    display: flex;
    margin-Top: 40px;
    margin-Bottom: 20px;
}

.login_resend {
    display: flex;
    width: 120px;
    height: 45px;
    margin-Left: 20px;
    justify-Content: center;
    align-Items: center;
    border: 1px solid #AAB9DD;
    border-Radius: 8px;
}

.login_account_div {
    display: flex;
    width: 272px;
    height: 45px;
    border-Radius: 10px;
    color: #fff;
    background: #FFF;
    border: none;
    flex-Direction: column;
}

.login_password_div {
    display: flex;
    width: 300px;
    height: 55px;
    border-Radius: 16px;
    background: #FFF;
    border: none;
    flex-Direction: row;
    margin-Top: 30px;
    align-Items: center;
}

.login_input {
    width: 200px;
    height: 20px;
    color: #666292;
    border: none;
    outline: none;
    padding-Left: 24px;
    border-Radius: 10px;
}

.login_div_text {
    width: 100%;
    color: rgb(104, 106, 117);
    display: flex;
    justify-Content: center;
    align-Items: center;
    margin-top: 16px;
}

.login_language {
    width: 100%;
    display: flex;
    justify-Content: flex-end;
    margin-Top: 48px;
    margin-Right: 72px;
}

.login_logo_div {
    display: flex;
    justify-Content: center;
    margin-Top: 20px;
    align-Items: center;
}

.login_check_div {
    display: inline-flex;
    width: 12px;
    height: 12px;
    justify-Content: center;
    align-Items: center;
    border: 1px solid #AAB9DD;
}

.login_check_text {
    color: #AAB9DD;
    height: 15px;
    font-Size: 13px;
    padding-Left: 5px;
    padding-Bottom: 5px;
}

.login_button {
    border-Radius: 16px;
    background: #4D5BD8;
    border: none;
    outline: none;
    color: #fff;
}

.login_account {
    display: flex;
    width: 120px;
    background: #fff;
    border-Bottom-Left-Radius: 16px;
    border-Top-Left-Radius: 16px;
}

.login_text {
    color: #AAB9DD;
    width: 200px;
    height: 15px;
    font-Size: 13px;
    display: inline-flex;
    padding-Left: 24px;
    padding-top: 5px;
}

.login_title {
    color: black;
    font-Size: 18px;
    font-Weight: bold;
    margin-Top: 42px;
    margin-Bottom: 40px;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #AAB9DD !important;
    background-color: transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s;
}

.login-phone-or-pre {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: none;
    border-radius: 13px;
    width: 300px;
    height: 55px;
}

.login-page-password-box {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    width: 300px;
    height: 60px;
    margin-top: 30px;
}