.tableComponent {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    // background: #f5f7fc;
    .headerTableCell {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .headerLable {
        font-family: "Krub Medium", "Krub";
        font-weight: 500;
        font-style: normal;
        font-size: 13px;
        color: #4265ed;
    }

    .sortButton {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        .down {
            font-size: 20px !important;
            &:nth-of-type(1) {
                transform: rotate(180deg);
                margin-bottom: -10px;
            }
        }
    }

    .Mui-selected {
        background: rgba(77, 91, 216, 0.1) !important;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #4d5bd8;
    }

    .noDate {
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        height: 300px;
        text-align: center;
    }

    .loadingBody {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
        z-index: 99;
        .tableRow {
            display: flex;
            width: 100%;
            height: 100%;
        }
        .tableCell {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 100vw !important;
        }
        .loadingIcon {
            color: #fff;
        }
    }
    .table {
        box-sizing: border-box;
        flex: 1;
    }
    .tableContainer {
        display: flex;
        // flex: 1;
        padding-bottom: 1px;
    }

    .MuiTableCell-stickyHeader {
        background: none;
    }
    .MuiTableCell-root {
        padding: 0px;
        font-size: 14px;
    }
    // .MuiTablePagination-root {
    //     height: 64px;
    // }
    ::-webkit-scrollbar { 
        width: 0;
    }
}

.container {
    margin: 12px 33px 0 33px;
    .title {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title-btn {
            margin-right: 22px;
            min-width: 0;
            width: 42px;
            height: 40px;
            background-color: #ff8058;
        }
    }

    .tool-bar-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 24px;
        .tool-bar-btn {
            margin-right: 22px;
            min-width: 0;
            width: 42px;
            height: 42px;
            background-color: #27d086;
        }
    }
    .groupTableManage {
        margin-bottom: 50px;
        width: 80%;
        height: 80%;
    }
}

.icon-btn {
    display: flex;
    flex-direction: row;
    .featureList {
        display: flex;
        justify-content: flex-end;
        margin: 5px;
        li {
            &:nth-of-type(2) {
                margin: 0 16px;
            }
            list-style: none;
        }
        .iconButton {
            border-radius: 4px;
        }
        .ico {
            color: white;
        }
    }
}


.dialog-header {
    padding: 3% 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EFF1F7;

    .header-title-box {
        display: flex; 
        align-items: center;
    }

    .header-title {
        padding-left: 16px;
        font-size: 16px;
        font-weight: bold;
    }

    .header-close-icon {
        font-size: 26px;
        color: #d6d6d6;
        cursor: pointer
    }
}

.dialog-footer {
    padding: 0 9% 7% 9%;
    display: flex;

    .footer-button {
        width: 42%;
        height: 48px;
    }
}


.deleteDialog{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 200px;
    background: #ffffff;
    header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
            line-height: 24px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            margin-left: 20px;
        }
    }
    .tip{
        flex: 1;
        line-height: 24px;
        font-size: 15px;
        color: #999;
        margin:0 20px;
    }
    .featureButton{
        display: flex;
        justify-content: flex-end;
        margin: 0 20px 20px 0;
    }
    .button{
        width: 100px;
        height: 40px;
        background: inherit;
        background-color: rgba(227, 232, 236, 0);
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(66, 101, 237, 1);
        border-radius: 4px;
        .MuiButton-label {
            height: 32px;
        }
    }
    .confirmButton{
        background: #4265ED;
        margin-left: 20px;
    }
    .buttonText{
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        color: #4265ED;
        text-align: center;
    }
    .confirmButtonText{
        color: white;
    }
}
.test-customer {
    text-align: left;
    padding: 40px 20px;

    .customer-componet-test {
        display: flex;
        justify-Content: flex-start;
        flex-Direction: column;
        border: 1px solid #fff;
        border-radius: 8px;
        // width:  calc(100% - 40px);
        background: #FFF;
        margin-top: 10px;
        position: relative;
        .top-bar {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 10px;
        }
    }
    .tableBody{
        height: unset;
    }
}


.customer-phone-new {
    display: contents;
}

.customer-phone-new-2 {
    width: 84%;
    height: 110px;
    margin-Top: 15px;
    padding: 20px 20px;
    background: #FFF;
    border-Radius: 8px;
    border-color: #F4F7FB;
    border: 1px solid #C9D8EF;
    box-shadow: 7px 10px 7px #F4F8FF;
}

.m12 {
    background-color: #EAF3FD !important;
}
